export default Object.freeze({
  HOME: "/",
  SIGN_UP: "/sign_up",
  PHOTO_UPLOAD: "/photo_upload",
  PHOTO_POST_UPLOADED: "/photo_post_uploaded",
  GENERATED_PHOTOS: "/generated_photos",
  PRIVACY: "/privacy",
  TERMS: "/terms",
  ORDER_PRODUCT_CONFIRMATION: "/order_product_confirmation",
  ORDER_PRODUCT: "/order_product",
  ORDER: "/order",
  PHOTO_SETS: "/photo_sets",
});
