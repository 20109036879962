import { Typography, Box } from "@mui/material";

function TermsScreen() {
  return (
    <>
      <Box
        sx={{
          marginLeft: { xs: 3, md: 6, lg: 8 },
          marginRight: { xs: 3, md: 6, lg: 8 },
          marginBottom: 8,
        }}
      >
        <Typography variant="h6">TERMS OF SERVICE - DAPPER AI</Typography>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">1.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Introduction</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Welcome to Dapper AI! These Terms of Service (“Terms”) regulate
                your use of the Dapper AI website (“Site”) and the services
                offered by Down App, Inc. (“we”, “us”, or “our”). Before
                accessing or using our Site or services, please carefully read
                these Terms.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">2.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Acceptance of Terms</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                By accessing or using our Site or services, you agree to abide
                by these Terms and our Privacy Policy. If you do not agree to
                these Terms or our Privacy Policy, please refrain from accessing
                or using our Site or services.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">3.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">User Eligibility</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Our Site and services are intended for users aged 18 and above.
                By accessing or using our Site or services, you confirm that you
                are at least 18 years old and legally capable of entering into a
                binding agreement. If you are below 18 years old or lack legal
                capacity, you may not access or use our Site or services.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">4.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Account Registration and Security
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                To utilize our services, you may need to create an account on
                our Site. You agree to furnish accurate and complete information
                during the account registration process and to maintain updated
                account information. You are responsible for safeguarding the
                confidentiality of your account credentials and for all
                activities conducted under your account. Promptly notify us of
                any unauthorized account access or security breaches.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">6.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">User Content and Guidelines</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Through our Site and services, you may submit photos and other
                content (“User Content”). You affirm that you have sole
                ownership or appropriate license to utilize the photos that you
                upload. While you retain ownership of your User Content, by
                submitting it, you grant us a non-exclusive, royalty-free,
                worldwide, perpetual license to utilize, modify, reproduce,
                publish, distribute, and display your User Content in connection
                with our Site and services. You affirm that you possess all
                requisite rights to grant us this license. You undertake not to
                submit any User Content that: Violates any applicable laws,
                regulations, or guidelines; Infringes on the intellectual
                property rights of others; Contains obscene, pornographic, or
                offensive material; Includes personal information about
                individuals under 18 years old; Contains harmful or malicious
                software. We reserve the right to remove or reject any User
                Content at our discretion.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">7.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Copyrights and Photo Ownership
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                All AI-generated photos provided by our Site and services are
                original works generated by our AI algorithms. You acknowledge
                that you do not acquire any ownership rights to the AI-generated
                photos and are granted a non-exclusive, royalty-free, worldwide
                license to use the AI-generated photos for personal or your own
                professional purposes only, excluding commercial services
                connected to a business, including but not limited to
                competitors. Commercial use of AI-generated photos necessitates
                our prior written consent.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">8.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                AI-generated Photo Guidelines
              </Typography>
            </Box>
            <Box>
              <Box sx={{ marginBottom: 2 }}>
                <Typography>
                  For optimal AI photos, adhere to these guidelines:
                </Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Upload a sufficient number of close-up pictures of only
                      yourself.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Ensure each photo features only one person.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Include pictures from varied days, environments, and
                      poses.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>d.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Submit high-quality, non-blurry photos.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>e.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Avoid uploading duplicate images.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3.5 }}>
                    <Typography>f.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Ensure your face remains uncovered.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>g.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Ensure full visibility of your hair.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>h.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography align="justify">
                      Understand that only photos of adults are permitted on the
                      platform; children's photos are prohibited. Compliance
                      with these guidelines enhances the quality of our
                      AI-generated photo services.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">9.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Intellectual Property Rights</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                All content, features, and functionalities on our Site and
                services, including text, graphics, logos, images, and software,
                are the exclusive property of Down App, Inc. or its licensors
                and are safeguarded by international copyright, trademark, and
                other intellectual property laws. You undertake not to infringe
                on our intellectual property rights or those of our licensors.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">10.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Acceptable Use Policy</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                You pledge to utilize our Site and services solely for lawful
                purposes and in accordance with these Terms. You agree not to:
                Engage in conduct that may harm or disrupt our Site, services,
                or other users; Employ our Site or services for fraudulent,
                deceptive, or unlawful activities; Access or attempt to access
                unauthorized sections of our Site or services; Use any automated
                means, such as bots or scrapers, to access, collect, or utilize
                information from our Site or services. Violations of this
                Acceptable Use Policy may result in termination of your access
                to our Site and services.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">11.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Refund and Cancellation Policy
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                It is expensive to generate AI photos, and we are not
                responsible for the final quality of the photos, as it is highly
                dependent on the photos that you upload. For these reasons, we
                do not issue refunds. However, if you feel that there is a
                technical issue that requires repair and credit for you to
                generate new photos, contact us at ops@downapp.com. Our customer
                service team will assess your request and verify adherence to
                our guidelines regarding submitted photos. If our service fails
                to meet your expectations, we will work with you to explore
                alternatives or resolutions. We strive for customer satisfaction
                and value your feedback to enhance our services.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">12.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Payment and Subscription Terms
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                By selecting a photo package or subscription plan and providing
                payment information, you agree to pay associated fees. All
                payments are processed via third-party payment processors,
                subject to their terms and conditions. We reserve the right to
                modify pricing, subscription options, features, rules, and
                payment terms at any time.
              </Typography>
            </Box>

            <Box sx={{ display: "flex", marginTop: 3 }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography>12.1</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box>
                  <Typography>Subscription</Typography>
                  <Typography align="justify">
                    Upon subscribing to the Service, your credit card will be
                    charged immediately. Refer to Section 12.3, Recurring
                    Payment, for further details. Subscription grants access to
                    create AI photos and download them. Storage of photos is
                    temporary, so please download your photos within thirty (30)
                    days of generation.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: 3 }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography>12.2</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box>
                  <Typography>Payment Methods</Typography>
                  <Typography align="justify">
                    We accept payments through Apple’s App Store, Stripe
                    (including major credit cards such as VISA, MasterCard).
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: 3 }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography>12.3</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box>
                  <Typography>Recurring Payment</Typography>
                  <Typography align="justify">
                    By providing your billing payment method and subscribing to
                    the Service, you authorize Dapper AI or its designated
                    payment processor to charge applicable monthly or yearly
                    subscription fees. Unless you cancel your subscription, it
                    will automatically renew at the end of each term. Renewals
                    are charged at prevailing rates, with notice provided for
                    fee increases.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: 3 }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography>12.4</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box>
                  <Typography>Billing Date</Typography>
                  <Typography align="justify">
                    Your credit card will be charged on your billing date
                    ("Billing Date") as per your subscription type. If your
                    Activation Date falls on the 29th, 30th, or 31st day of a
                    month lacking such days in a subsequent month, billing
                    occurs on the month's last day.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: 3 }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography>12.5</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box>
                  <Typography>Invoicing</Typography>
                  <Typography align="justify">
                    Invoices are generated on the billing date. Taxes applied
                    depend on the service usage country, determined by your
                    indicated country during checkout and obtained IP address.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: 3 }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography>12.6</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box>
                  <Typography>Late Payments</Typography>
                  <Typography align="justify">
                    Subscription deactivation may occur without notice for
                    overdue payments or declined payments. Notification emails
                    are sent for payment processing issues. After three failed
                    attempts, subscription cancellation ensues, necessitating
                    re-subscription.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: 3 }}>
              <Box sx={{ marginRight: 3 }}>
                <Typography>12.7</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box>
                  <Typography>Early Cancellation</Typography>
                  <Typography align="justify">
                    You may cancel your subscription anytime, retaining access
                    until the next Billing Date.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">13.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Technical Requirements and Compatibility
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Our Site and services are designed for compatibility with modern
                devices and internet connections. Access and usage necessitate
                compatible devices and stable internet connections. We are not
                liable for device or internet connection-related issues
                affecting access or usage.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">14.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">DMCA</Typography>
            </Box>
            <Box>
              <Box>
                <Typography align="justify">
                  We respect intellectual property rights and address legitimate
                  copyright infringement claims per the Digital Millennium
                  Copyright Act (DMCA). Notify us at ops@downapp.com with:
                </Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 3 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      A description of the copyrighted work;
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      Details of the alleged infringing material's location;
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>Your contact information;</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>d.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>A statement of good faith belief;</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>e.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      A statement under penalty of perjury; and
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3.5 }}>
                    <Typography>f.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      An electronic or physical signature.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">15.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Community Guidelines</Typography>
            </Box>
            <Box>
              <Box>
                <Typography align="justify">
                  We uphold a safe and respectful environment by enforcing
                  community guidelines:
                </Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 3 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>Treat others with respect;</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      Avoid harassment, bullying, or discrimination;
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      Obtain consent before sharing personal information;
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>d.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      Refrain from impersonation or deceptive activities;
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>e.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      Abstain from promoting or engaging in illegal activities;
                      and
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3.5 }}>
                    <Typography>f.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>
                      Violations may result in content removal, account
                      suspension, or other appropriate actions.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">16.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Limitation of Liability</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Down App, Inc., its officers, directors, employees, or agents
                shall not be liable for direct, indirect, incidental, special,
                consequential, or punitive damages arising from your access to
                or use of our Site or services. ‍
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">17.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Disclaimers and Warranties</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Our Site and services are provided "as is" and "as available"
                without warranties, express or implied. We do not guarantee
                uninterrupted, error-free, secure, or virus-free operation. ‍ ‍
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">18.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Indemnification</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                You agree to indemnify and hold harmless Down App, Inc., its
                officers, directors, employees, and agents from claims,
                liabilities, damages, losses, or expenses arising from your
                access to or use of our Site or services, your violation of
                these Terms, or infringement of third-party rights.‍
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">19.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Governing Law and Jurisdiction
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                These Terms are governed by Delaware law, with disputes subject
                to the exclusive jurisdiction of Delaware, USA courts.‍
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">20.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Dispute Resolution</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Disputes shall be resolved through negotiations; unresolved
                disputes may proceed to binding arbitration under mutually
                agreed-upon rules.‍
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">21.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Privacy Policy</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Review our Privacy Policy for information on personal data
                collection, use, and disclosure.‍
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">21.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Privacy Policy</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Review our Privacy Policy for information on personal data
                collection, use, and disclosure.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">22.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Third-Party Links and Services
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                We do not control third-party websites or services linked to our
                Site, disclaiming responsibility for their content or practices.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">23.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Modifications to the Terms</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                We may modify these Terms at our discretion, notifying users of
                changes.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">24.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Termination</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                We reserve the right to terminate access to our Site and
                services for any reason, with surviving provisions
                post-termination.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">25.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Assignment</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                These Terms are not assignable without consent, though we
                reserve the right to assign without notice.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">26.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Severability</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Invalid provisions shall not affect the validity of the
                remaining Terms.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">27.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Entire Agreement</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                These Terms, along with our Privacy Policy, constitute the
                entire agreement, superseding prior agreements.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">28.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Contact Information</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                For queries or concerns, contact us at{" "}
                <a
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  href="mailto:human@dapper-ai.com"
                >
                  human@dapper-ai.com
                </a>
              </Typography>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <Typography align="justify">Physical address:</Typography>
              <Typography align="justify">
                8 The Green, Suite # 12693, Dover, DE, 19901
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography>Effective date: Feb 14, 2024</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TermsScreen;
