import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { delReq, postReq } from "../../services/api.services";
import { RootState } from "../store";
import Cookies from "js-cookie";

export const createSession = createAsyncThunk(
  "private/api/v1/sessions",
  async (payload: { email: string }) => {
    const response = await postReq("/private/api/v1/sessions", payload);
    return response;
  }
);

export const removeSession = createAsyncThunk("auth/sign_out", async () => {
  const response = await delReq("/auth/sign_out", null);
  return response;
});

type SliceState = {
  user: {
    email: string | null;
    credit_balance: number;
  };
  createSessionObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
  removeSessionObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
};

const initialState: SliceState = {
  user: {
    email: null,
    credit_balance: 0,
  },
  createSessionObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
  removeSessionObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSession.pending, (state) => {
      state.createSessionObj.status = "pending";
    });
    builder.addCase(createSession.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.createSessionObj.status = "succeeded";
      state.createSessionObj.data = data;
      state.user.email = data.email;
      state.user.credit_balance = data.credit_balance;
      Cookies.set("auth_token", data.auth_token, { expires: 30 });
      Cookies.set("email", data.email, { expires: 30 });
      Cookies.set("credit_balance", data.credit_balance, { expires: 30 });
    });
    builder.addCase(createSession.rejected, (state, action) => {
      const { message } = action.error;
      state.createSessionObj.status = "failed";
      if (!message) return;
      state.createSessionObj.errorMessage = message;
      state.user.email = null;
      Cookies.remove("auth_token");
      Cookies.remove("email");
      Cookies.remove("credit_balance");
      Cookies.remove("remote_uuid");
    });
    builder.addCase(removeSession.pending, (state) => {
      state.removeSessionObj.status = "pending";
    });
    builder.addCase(removeSession.fulfilled, (state) => {
      state.removeSessionObj.status = "succeeded";
      Cookies.remove("auth_token");
      Cookies.remove("email");
      Cookies.remove("credit_balance");
      Cookies.remove("remote_uuid");
      state.user = {
        email: null,
        credit_balance: 0,
      };
    });
    builder.addCase(removeSession.rejected, (state) => {
      state.removeSessionObj.status = "failed";
      Cookies.remove("auth_token");
      Cookies.remove("email");
      Cookies.remove("credit_balance");
      Cookies.remove("remote_uuid");
      state.user = {
        email: null,
        credit_balance: 0,
      };
      state.removeSessionObj.errorMessage = "Issue removing token";
    });
  },
});

export default authSlice.reducer;

export const authSelector = (state: RootState) => state.auth;

export const { setUser } = authSlice.actions;
