function ReUploadIcon() {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none">
      <g id="tabler:reload">
        <g id="Group">
          <path
            id="Vector"
            d="M25.1061 16.6571C24.8417 18.6731 24.0336 20.579 22.7683 22.1706C21.5031 23.7621 19.8285 24.9792 17.9241 25.6914C16.0197 26.4035 13.9573 26.5838 11.9582 26.213C9.95914 25.8421 8.09872 24.934 6.57648 23.5862C5.05425 22.2383 3.92763 20.5015 3.31745 18.5621C2.70728 16.6226 2.63656 14.5536 3.11289 12.577C3.58922 10.6004 4.59462 8.79068 6.02128 7.34204C7.44793 5.8934 9.24201 4.86044 11.2111 4.35395C16.6697 2.95395 22.3201 5.76375 24.4061 10.9997"
            stroke="#F92F6C"
            strokeWidth="2.83203"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M25.2 4V11H18.2"
            stroke="#F92F6C"
            strokeWidth="2.83203"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default ReUploadIcon;
