import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import Lottie from "react-lottie";

import { useAppDispatch } from "../../../store/store";
import {
  getAIPhotosByUUID,
  setReduxStoreFirstRender,
} from "../../../store/photo/photo-slice.service";

import routes from "../../../infrastructure/navigation/routes";
import { IntervalHooks } from "../../../infrastructure/utils/interval-hooks.utils";
import HeaderContent from "../../../infrastructure/layout/components/header-content.component";

import * as LoaderIconBubble from "../../../fixtures/lottie/loading-icon/loader.json";
import ErrorIcon from "../../../fixtures/icons/error.icon";

import Spacer from "../../../components/utils/spacer.component";
import CtaButton from "../../../components/utils/cta-button.component";
import ContentContainer from "../../../components/utils/content-container.component";

function PhotoPostUploadedScreen() {
  const steps: {
    header: string;
    subheader: string;
    loaderMessages: { message: string; duration: number }[];
    buttonText: string;
    buttonBackground: string;
  }[] = [
    {
      header: "Generating your photos...",
      subheader: "Working my AI magic. This could take up to 30 min",
      buttonText: "Next",
      loaderMessages: [
        { message: "Detecting faces and features in images", duration: 180 },
        { message: "Creating your AI model", duration: 600 },
        { message: "Generating business images", duration: 240 },
      ],
      buttonBackground:
        "radial-gradient(50.46% 66.91% at 49.77% 100.00%, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.00) 100%), #C957F1",
    },
    {
      header: "Opps. Something went wrong",
      subheader: "There was an error with your photos. Please try again.",
      loaderMessages: [],
      buttonText: "Try again",
      buttonBackground:
        "radial-gradient(50.46% 66.91% at 49.77% 100%, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.00) 100%), #F92F6C",
    },
  ];

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [currentStage, setCurrentStage] = useState("preparing_photos");
  const [indexLoaderMessages, setIndexLoaderMessages] = useState(0);
  const [maxProgressIndicator, setMaxProgressIndicator] = useState(95);
  const [progressIndicator, setProgressIndicator] = useState(
    index === 0
      ? maxProgressIndicator /
          steps[index]["loaderMessages"][indexLoaderMessages].duration
      : 0
  );
  const [counter, setCounter] = useState(
    index === 0
      ? maxProgressIndicator /
          steps[index]["loaderMessages"][indexLoaderMessages].duration
      : 0
  );
  const [stopCounter, setStopCounter] = useState(false);
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  const pingAPI = () => {
    const uuid: string = Cookies.get("remote_uuid") as string;
    if (uuid) {
      dispatch(getAIPhotosByUUID({ uuid })).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          if (payload.data.status === "pending") {
            if (
              payload.data.remote_stage === currentStage &&
              payload.data.categorised_remote_images.length === 0
            ) {
              setCounter(
                maxProgressIndicator /
                  steps[index]["loaderMessages"][indexLoaderMessages].duration
              );
            } else if (
              payload.data.remote_stage === "training_model" &&
              payload.data.photo_preparation_succeeded_at
            ) {
              setCurrentStage("training_model");
              setMaxProgressIndicator(100);
            } else if (
              payload.data.remote_stage === "generating_photos" &&
              payload.data.categorised_remote_images.length === 0
            ) {
              setCurrentStage("generating_photos");
              setMaxProgressIndicator(100);
            } else if (
              payload.data.remote_stage === "generating_photos" &&
              payload.data.categorised_remote_images.length > 0
            ) {
              setCurrentStage("generating_photos");
              setMaxProgressIndicator(100);
              setStopCounter(true);
            }
          } else if (payload.data.status === "failed") {
            setIndex(1);
          }
        }
      });
    }
  };

  const progressUpdate = () => {
    if (index === 0 && counter < 900) {
      setCounter(
        counter +
          maxProgressIndicator /
            steps[index]["loaderMessages"][indexLoaderMessages].duration
      );
      if (maxProgressIndicator > 95) {
        setProgressIndicator(
          progressIndicator +
            maxProgressIndicator /
              steps[index]["loaderMessages"][indexLoaderMessages].duration
        );
        if (progressIndicator >= 100) {
          if (stopCounter) {
            setCounter(900);
            setIndexLoaderMessages(2);
            dispatch(setReduxStoreFirstRender(true));
            navigate(routes.GENERATED_PHOTOS);
          } else {
            setMaxProgressIndicator(95);
            let newIndexLoaderMessages = 0;
            if (currentStage === "training_model") {
              newIndexLoaderMessages = 1;
              setIndexLoaderMessages(1);
            } else if (currentStage === "generating_photos") {
              newIndexLoaderMessages = 2;
              setIndexLoaderMessages(2);
            }
            setCounter(
              maxProgressIndicator /
                steps[index]["loaderMessages"][newIndexLoaderMessages].duration
            );
            setProgressIndicator(
              maxProgressIndicator /
                steps[index]["loaderMessages"][newIndexLoaderMessages].duration
            );
          }
        }
      } else {
        if (counter >= progressIndicator) {
          setProgressIndicator(
            progressIndicator +
              maxProgressIndicator /
                steps[index]["loaderMessages"][indexLoaderMessages].duration
          );
        }
        if (counter >= 80) {
          pingAPI();
        }
      }
    } else {
      dispatch(setReduxStoreFirstRender(true));
      navigate(routes.GENERATED_PHOTOS);
    }
  };

  IntervalHooks.useDispatch(() => {
    index === 0 && progressUpdate();
  }, 1000);

  useEffect(() => {
    function firstPingAPI() {
      const uuid: string = Cookies.get("remote_uuid") as string;
      if (uuid) {
        dispatch(getAIPhotosByUUID({ uuid })).then(({ meta, payload }) => {
          if (meta.requestStatus === "fulfilled") {
            if (payload.data.status === "pending") {
              if (
                payload.data.remote_stage === "training_model" &&
                payload.data.photo_preparation_succeeded_at
              ) {
                setCurrentStage("training_model");
                setMaxProgressIndicator(95);
                setIndexLoaderMessages(1);
              } else if (
                payload.data.remote_stage === "generating_photos" &&
                payload.data.categorised_remote_images.length === 0
              ) {
                setCurrentStage("generating_photos");
                setMaxProgressIndicator(95);
                setIndexLoaderMessages(2);
              } else if (
                payload.data.remote_stage === "generating_photos" &&
                payload.data.categorised_remote_images.length > 0
              ) {
                setCurrentStage("generating_photos");
                setMaxProgressIndicator(100);
                setIndexLoaderMessages(2);
                setStopCounter(true);
                setCounter(900);
                setProgressIndicator(100);
              }
            } else if (payload.data.status === "failed") {
              setIndex(1);
            } else if (payload.data.status === "success") {
              navigate(routes.GENERATED_PHOTOS);
            }
          }
        });
      } else {
        navigate(routes.PHOTO_UPLOAD);
      }
    }
    firstPingAPI();
  }, [dispatch, navigate]);

  return (
    <>
      <ContentContainer>
        <HeaderContent hideBackBtn={true} disabledCreditBtn={true} />
        <Grid
          container
          sx={{
            borderRadius: { xs: 0, md: "50px 50px 0px 0px" },
            borderTop: {
              md: "4px solid rgba(217, 217, 217, 0.05)",
            },
            borderRight: {
              md: "4px solid rgba(217, 217, 217, 0.05)",
            },
            borderLeft: { md: "4px solid rgba(217, 217, 217, 0.05)" },
            background: {
              md: "linear-gradient(340deg, rgba(237, 85, 158, 0.15) 0%, rgba(138, 91, 238, 0.15) 100%)",
            },
            minHeight: "calc(100vh - 80px)",
            padding: { xs: 0, md: 4 },
          }}
        >
          <Grid item xs={12} sx={{ marginTop: 4 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <Box
                  component="div"
                  sx={{
                    background: "url('/images/corgi/corgi-shaded-1.png')",
                    height: { xs: "159px", md: "195px" },
                    width: { xs: "159px", md: "195px" },
                    flexShrink: 0,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                  }}
                >
                  {index === 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: belowBreakPoint ? 25 : 30,
                        right: belowBreakPoint ? -65 : -85,
                      }}
                    >
                      <Lottie
                        height={belowBreakPoint ? 100 : 130}
                        width={belowBreakPoint ? 105 : 135}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: LoaderIconBubble,
                        }}
                      />
                    </Box>
                  )}
                  {index === 1 && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: belowBreakPoint ? 25 : 30,
                        right: belowBreakPoint ? -65 : -85,
                      }}
                    >
                      <ErrorIcon />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={10}>
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.text.secondary,
                      textAlign: "center",
                    }}
                  >
                    {`${steps[index].header}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index === 1 && (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Spacer size="l" position="top" />
                    <Typography
                      variant="h5"
                      sx={{ textAlign: "center" }}
                    >{`${steps[index].subheader}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {index === 0 && (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={10} sx={{ marginTop: 16 }}>
                    <ProgressBar
                      completed={progressIndicator}
                      isLabelVisible={false}
                      baseBgColor="#301B37"
                      bgColor="#D376F4"
                      height="10px"
                    />
                    <Spacer position="top" size="m" />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography variant="h5">
                          {
                            steps[index].loaderMessages[indexLoaderMessages]
                              .message
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={2} justifyContent="flex-end">
                        <Grid container justifyContent="flex-end">
                          <Typography variant="h5">
                            {progressIndicator >= 100
                              ? 100
                              : Math.floor(progressIndicator)}
                            %
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {index === 1 && (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item sx={{ marginTop: "64px", marginBottom: "64px" }}>
                    <CtaButton
                      background={steps[1].buttonBackground}
                      text={steps[1].buttonText}
                      onClick={() => {
                        Cookies.remove("remote_uuid");
                        navigate(routes.PHOTO_UPLOAD);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
}

export default PhotoPostUploadedScreen;
