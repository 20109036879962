import { useTheme, useMediaQuery } from "@mui/material";
function BackArrowIcon() {
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {belowBreakPoint ? (
        <>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M10.2951 19.716C10.4849 19.8973 10.7383 19.9967 11.0008 19.9927C11.2632 19.9888 11.5136 19.8818 11.6979 19.6949C11.8821 19.508 11.9855 19.2562 11.9858 18.9937C11.986 18.7313 11.883 18.4792 11.6991 18.292L6.32908 13L19.9991 13C20.2643 13 20.5186 12.8946 20.7062 12.7071C20.8937 12.5196 20.9991 12.2652 20.9991 12C20.9991 11.7348 20.8937 11.4804 20.7062 11.2929C20.5186 11.1054 20.2643 11 19.9991 11L6.33508 11L11.6991 5.715C11.883 5.52778 11.986 5.27575 11.9858 5.01328C11.9855 4.75081 11.8821 4.49896 11.6979 4.31206C11.5136 4.12516 11.2632 4.0182 11.0008 4.01426C10.7383 4.01032 10.4849 4.10972 10.2951 4.291L3.37008 11.113C3.25216 11.2293 3.15853 11.3679 3.09462 11.5206C3.03071 11.6734 2.9978 11.8374 2.9978 12.003C2.9978 12.1686 3.03071 12.3326 3.09462 12.4854C3.15853 12.6382 3.25216 12.7767 3.37008 12.893L10.2941 19.716L10.2951 19.716Z"
              fill="white"
            />
          </svg>
        </>
      ) : (
        <>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M10.7951 20.2161C10.9849 20.3973 11.2383 20.4967 11.5008 20.4928C11.7632 20.4889 12.0136 20.3819 12.1979 20.195C12.3821 20.0081 12.4855 19.7563 12.4858 19.4938C12.486 19.2313 12.383 18.9793 12.1991 18.7921L6.82908 13.5001L20.4991 13.5001C20.7643 13.5001 21.0186 13.3947 21.2062 13.2072C21.3937 13.0196 21.4991 12.7653 21.4991 12.5001C21.4991 12.2348 21.3937 11.9805 21.2062 11.793C21.0186 11.6054 20.7643 11.5001 20.4991 11.5001L6.83508 11.5001L12.1991 6.21506C12.383 6.02785 12.486 5.77581 12.4858 5.51334C12.4855 5.25088 12.3821 4.99902 12.1979 4.81212C12.0136 4.62522 11.7632 4.51826 11.5008 4.51432C11.2383 4.51038 10.9849 4.60978 10.7951 4.79106L3.87008 11.6131C3.75216 11.7294 3.65853 11.8679 3.59462 12.0207C3.53071 12.1735 3.4978 12.3375 3.4978 12.5031C3.4978 12.6687 3.53071 12.8326 3.59462 12.9854C3.65853 13.1382 3.75216 13.2768 3.87008 13.3931L10.7941 20.2161L10.7951 20.2161Z"
              fill="white"
            />
          </svg>
        </>
      )}
    </>
  );
}

export default BackArrowIcon;
