function CheckIcon({ color }: { color: string }) {
  return (
    <>
      <svg width="25" height="25" fill="none">
        <g id="ph:check-bold">
          <path
            id="Vector"
            d="M1.99756 11.2501C1.99756 11.398 2.0267 11.5445 2.08332 11.6812C2.13994 11.8179 2.22292 11.9421 2.32754 12.0467L6.70231 16.4215C6.70236 16.4215 6.70241 16.4216 6.70246 16.4216C6.80694 16.5264 6.93108 16.6096 7.06776 16.6664C7.20451 16.7231 7.35112 16.7524 7.49918 16.7524C7.64725 16.7524 7.79386 16.7231 7.9306 16.6664C8.06729 16.6096 8.19143 16.5264 8.29591 16.4216C8.29596 16.4216 8.29601 16.4215 8.29606 16.4215L18.1632 6.55436L18.1647 6.55592L18.2974 6.42327C18.5087 6.21199 18.6274 5.92543 18.6274 5.62663C18.6274 5.32783 18.5087 5.04126 18.2974 4.82998C18.0861 4.6187 17.7995 4.5 17.5007 4.5C17.2019 4.5 16.9154 4.6187 16.7041 4.82998L7.49998 14.0326L3.92083 10.4534C3.81621 10.3488 3.69201 10.2658 3.55533 10.2092C3.41864 10.1526 3.27214 10.1234 3.12418 10.1234C2.97623 10.1234 2.82973 10.1526 2.69304 10.2092C2.55635 10.2658 2.43216 10.3488 2.32754 10.4534C2.22292 10.558 2.13994 10.6822 2.08332 10.8189C2.0267 10.9556 1.99756 11.1021 1.99756 11.2501Z"
            fill={color}
            stroke={color}
            strokeWidth="0.375"
          />
        </g>
      </svg>
    </>
  );
}

export default CheckIcon;
