import React, { useEffect, useState } from "react";
import {
  Typography,
  useMediaQuery,
  Pagination,
  useTheme,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

import {
  getPhotoSets,
  photoSelector,
  setReduxStorePhotoSets,
} from "../../../store/photo/photo-slice.service";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import HeaderContent from "../../../infrastructure/layout/components/header-content.component";

import Spacer from "../../../components/utils/spacer.component";

import PhotoSet from "../components/photo-sets/photo-set.component";
import routes from "../../../infrastructure/navigation/routes";

function PhotoSetsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const { reduxStorePhotoSets, getPhotoSetsObj } =
    useAppSelector(photoSelector);
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(setReduxStorePhotoSets([]));
    dispatch(getPhotoSets({ page })).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPage(payload.data.pagination.total_pages);
        const data = payload.data.items.map((item: any) => ({
          id: item.id,
          remote_uuid: item.remote_uuid,
          status: item.status,
          created_at: item.created_at,
          categorised_remote_images: item.categorised_remote_images.map(
            (remote_image: any) => ({
              category: remote_image.category,
              photos: remote_image.photos,
            })
          ),
        }));
        dispatch(setReduxStorePhotoSets(data));
      }
    });
  }, [page, dispatch, totalPage]);

  return (
    <>
      <HeaderContent handleBack={() => navigate(routes.PHOTO_UPLOAD)} />
      <Spacer position="top" size="xl" />
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          sx={{ margin: belowBreakPoint ? "0px" : "0px 24px" }}
        >
          <Grid
            container
            sx={{
              borderTopLeftRadius: "50px",
              borderTopRightRadius: "50px",
              borderTop: "4px solid #E8E8E8",
              borderRight: "4px solid #E8E8E8",
              borderLeft: "4px solid #E8E8E8",
              background: "#F8F3FA",
              minHeight: "calc(100vh - 80px)",
              padding: "16px",
            }}
          >
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  sx={{
                    marginTop: belowBreakPoint ? "24px" : "60px",
                    marginBottom: "24px",
                  }}
                >
                  <Typography variant="banner">My AI Photos</Typography>
                </Grid>
                {getPhotoSetsObj.status === "pending" && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    sx={{ marginTop: "calc(100vh/2 - 250px)" }}
                  >
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#b8b8b8"
                      ariaLabel="three-dots-loading"
                      visible={true}
                    />
                  </Grid>
                )}
                {getPhotoSetsObj.status === "succeeded" &&
                  reduxStorePhotoSets.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          {reduxStorePhotoSets.map((item) => (
                            <Grid
                              item
                              key={`${item.id}-${item.created_at}`}
                              sx={{
                                borderRadius: "20px",
                                border: "2px solid #E8E8E8",
                                background:
                                  "linear-gradient(0deg, rgba(201, 87, 241, 0.02) 0%, rgba(201, 87, 241, 0.02) 100%), #F8F3FA",
                                boxShadow:
                                  "0px 8px 24px 0px rgba(4, 2, 5, 0.03)",
                                padding: "24px",
                                margin: belowBreakPoint ? "10px 0px" : "20px",
                                width: belowBreakPoint ? "344px" : "392px",
                              }}
                            >
                              <PhotoSet item={item} />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  )}
                {getPhotoSetsObj.status === "succeeded" && totalPage > 1 && (
                  <>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      sx={{ margin: "36px 0px" }}
                    >
                      <Pagination
                        count={totalPage}
                        color="secondary"
                        page={page}
                        onChange={(e, newPage) => {
                          setPage(newPage);
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PhotoSetsScreen;
