import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";

import AppLayout from "../layout/app.layout";
import PublicRoute from "./public-route";
import PrivateRoute from "./private-route";

import HomeScreen from "../../screens/home.screen";
import SignUpScreen from "../../features/auth/screens/sign-up.screen";
import PhotoUploadScreen from "../../features/photo-upload/screens/photo-upload.screen";
import PhotoPostUploadedScreen from "../../features/photo-upload/screens/photo-post-uploaded.screen";
import GeneratedPhotosScreen from "../../features/photo-upload/screens/generated-photos.screen";
import TermsScreen from "../../features/photo-upload/screens/terms.screen";
import PrivacyScreen from "../../features/photo-upload/screens/privacy.screen";
import OrderProductConfirmationScreen from "../../features/order/screens/order-product-confirmation.screen";
import OrderProductScreen from "../../features/order/screens/order-product.screen";
import OrderScreen from "../../features/order/screens/order.screen";
import PhotoSetsScreen from "../../features/photo-sets/screens/photo-sets.screen";
import PhotoSetPhotosScreen from "../../features/photo-sets/screens/photo-set-photos.screen";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route element={<PublicRoute />}>
            <Route path={routes.HOME} element={<HomeScreen />} />
            <Route path={routes.SIGN_UP} element={<SignUpScreen />} />
            <Route path={routes.TERMS} element={<TermsScreen />} />
            <Route path={routes.PRIVACY} element={<PrivacyScreen />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={routes.PHOTO_UPLOAD} element={<PhotoUploadScreen />} />
            <Route
              path={routes.PHOTO_POST_UPLOADED}
              element={<PhotoPostUploadedScreen />}
            />
            <Route
              path={routes.GENERATED_PHOTOS}
              element={<GeneratedPhotosScreen />}
            />
            <Route
              path={routes.ORDER_PRODUCT_CONFIRMATION}
              element={<OrderProductConfirmationScreen />}
            />
            <Route
              path={routes.ORDER_PRODUCT}
              element={<OrderProductScreen />}
            />
            <Route path={routes.ORDER} element={<OrderScreen />} />
            <Route path={routes.PHOTO_SETS} element={<PhotoSetsScreen />} />
            <Route
              path={`${routes.PHOTO_SETS}/:id`}
              element={<PhotoSetPhotosScreen />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
