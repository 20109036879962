import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    brand?: {
      primary?: string;
      secondary?: string;
    };
    ui?: {
      primary?: string;
      secondary?: string;
    };
  }
  interface TypeBackground {
    secondary?: string;
    tertiary?: string;
  }
  interface TypeText {
    white?: string;
  }
  interface TypographyVariantsOptions {
    banner: {
      color?: string;
      fontFamily?: string;
      fontSize?: string;
      fontStyle?: string;
      lineHeight?: string;
      fontWeight?: number;
    };
    subbanner: {
      color?: string;
      fontFamily?: string;
      fontSize?: string;
      fontStyle?: string;
      lineHeight?: string;
      fontWeight?: number;
    };
    price: {
      fontFamily?: string;
      fontSize?: number;
      fontWeight?: number;
      fontStyle?: string;
      lineHeight?: string;
      background?: string;
      WebkitBackgroundClip?: string;
      WebkitTextFillColor?: string;
      color?: string;
    };
    headerLogo: {
      fontFamily?: string;
      fontSize?: number;
      fontWeight?: number;
      fontStyle?: string;
      lineHeight?: string;
      background?: string;
      WebkitBackgroundClip?: string;
      WebkitTextFillColor?: string;
      color?: string;
    };
    logo: {
      fontFamily?: string;
      fontSize?: number;
      fontWeight?: number;
      fontStyle?: string;
      lineHeight?: string;
      background?: string;
      WebkitBackgroundClip?: string;
      WebkitTextFillColor?: string;
      color?: string;
    };
    sublogo: {
      color?: string;
      fontFamily?: string;
      fontSize?: number;
      fontStyle?: string;
      fontWeight?: number;
      lineHeight?: string;
      background?: string;
      WebkitBackgroundClip?: string;
      WebkitTextFillColor?: string;
    };
    ctaButton: {
      fontSize?: string;
      fontWeight?: number;
      disabled?: boolean;
      fontFamily?: string;
      lineHeight?: string;
      color?: string;
    };
    inverse: {
      color?: string;
      fontSize?: string;
    };
    h3: {
      fontFamily?: string;
      fontSize?: number;
      fontWeight?: number;
      fontStyle?: string;
      lineHeight?: string;
      background?: string;
      WebkitBackgroundClip?: string;
      WebkitTextFillColor?: string;
    };
    h4: {
      fontFamily?: string;
      fontSize?: number;
      fontWeight?: number;
      fontStyle?: string;
      lineHeight?: string;
      background?: string;
      WebkitBackgroundClip?: string;
      WebkitTextFillColor?: string;
    };
    h5: {
      color?: string;
      fontFamily?: string;
      fontSize?: number;
      fontStyle?: string;
      fontWeight?: number;
      lineHeight?: string;
    };
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    banner: true;
    subbanner: true;
    price: true;
    headerLogo: true;
    logo: true;
    sublogo: true;
    ctaButton: true;
    inverse: true;
    h3: true;
    h4: true;
    h5: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    cta: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 390,
    md: 768,
    lg: 900,
    xl: 1500,
  },
};

const theme = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          borderRadius: 50,
          backgroundColor: "#262626",
          "&:hover": {
            backgroundColor: "#b8b8b8",
          },
          ":disabled": {
            backgroundColor: "#AAAAAA",
            color: "#fff",
          },
        },
      },
      variants: [
        {
          props: { variant: "cta" },
          style: {
            textTransform: "none",
            borderRadius: 50,
            [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
              height: "64px",
              width: "240px",
            },
            [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
              height: "64px",
              width: "240px",
            },
            [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
              height: "64px",
              width: "260px",
            },
            [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
              height: "64px",
              width: "280px",
            },
            ":hover": {
              opacity: 0.8,
              background:
                "radial-gradient(50.46% 66.91% at 49.77% 100.00%, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.00) 100%), #C957F1",
            },
            ":disabled": {
              opacity: 0.4000000059604645,
              background:
                "radial-gradient(50.46% 66.91% at 49.77% 100.00%, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.00) 100%), #C957F1",
            },
          },
        },
      ],
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& li .Mui-selected": {
            color: "#FFF !important",
            backgroundColor: "#C957F1",
          },
          "& li .MuiButtonBase-root": {
            color: "#040205",
          },
        },
      },
    },
  },
  breakpoints,
  palette: {
    text: {
      primary: "#fff",
      secondary: "#b8b8b8",
    },
    background: {
      default: "#000",
      secondary: "#141414",
      tertiary: "#FFF",
    },
  },
  typography: {
    fontFamily: "Switzer",
    button: {
      textTransform: "none",
      fontSize: "14px",
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontSize: "16px",
      },
      fontWeight: 600,
    },
    banner: {
      color: "#040205",
      fontFamily: "Bai Jamjuree",
      fontSize: "24px",
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: "40px",
      },
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontSize: "40px",
      },
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },
    subbanner: {
      color: "#040205",
      fontFamily: "Bai Jamjuree",
      fontStyle: "normal",
      fontSize: "25px",
      fontWeight: 700,
      lineHeight: "110%",
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: "50px",
      },
    },
    ctaButton: {
      fontSize: "22px",
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontSize: "25px",
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: "25px",
      },
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontSize: "25px",
      },
      fontWeight: 600,
      fontFamily: "Bai Jamjuree",
      color: "#FFF",
    },
    price: {
      fontFamily: "Bai Jamjuree",
      fontSize: 24,
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontSize: 30,
      },
      fontStyle: "normal",
      fontWeight: 900,
      lineHeight: "normal",
      background:
        "linear-gradient(90deg, rgba(255, 255, 255, 0.51) 5.58%, #FFF 47.75%, rgba(255, 255, 255, 0.63) 102.46%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    headerLogo: {
      fontFamily: "Bai Jamjuree",
      fontSize: 40,
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontSize: 30,
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 45,
      },
      fontStyle: "normal",
      fontWeight: 900,
      lineHeight: "normal",
      background:
        "linear-gradient(145deg, rgba(255, 255, 255, 0.51) 0%, #FFF 43.52%, rgba(255, 255, 255, 0.63) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    logo: {
      fontFamily: "Switzer",
      fontSize: 15,
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontSize: 25,
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 28,
      },
      fontStyle: "normal",
      fontWeight: 900,
      lineHeight: "normal",
      background:
        "linear-gradient(145deg, rgba(255, 255, 255, 0.51) 0%, #FFF 43.52%, rgba(255, 255, 255, 0.63) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    sublogo: {
      color: "#fff",
      fontFamily: "Switzer",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      background:
        "linear-gradient(145deg, rgba(255, 255, 255, 0.51) 0%, #FFF 43.52%, rgba(255, 255, 255, 0.63) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    inverse: {
      color: "rgba(255, 255, 255, 0.50)",
      fontSize: "1rem",
    },
    h3: {
      fontFamily: "Bai Jamjuree",
      fontSize: 30,
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontSize: 40,
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 60,
      },
      fontWeight: 700,
      fontStyle: "normal",
      lineHeight: "120%",
      background:
        "linear-gradient(145deg, rgba(255, 255, 255, 0.51) 0%, #FFF 43.52%, rgba(255, 255, 255, 0.63) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    h4: {
      fontFamily: "Bai Jamjuree",
      fontSize: 16,
      [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 25,
      },
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "130%",
      background:
        "linear-gradient(145deg, #0D0D0E 0%, rgba(13, 13, 14, 0.84) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    h5: {
      color: "rgba(255, 255, 255, 0.7)",
      fontFamily: "Switzer",
      fontSize: 16,
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontSize: 24,
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 20,
      },
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 100,
      maxHeight: 100,
    },
  },
});

export default theme;
