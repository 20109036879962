import { useState } from "react";
import { Grid, Box, Typography, Snackbar } from "@mui/material";
import Cookies from "js-cookie";
import ProgressBar from "@ramonak/react-progress-bar";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { IntervalHooks } from "../../../../infrastructure/utils/interval-hooks.utils";
import {
  getAIPhotosByUUID,
  photoSelector,
  setReduxStoreHikingPhotos,
  setReduxStoreHoldingPuppyPhotos,
  setReduxStoreRockstarPhotos,
} from "../../../../store/photo/photo-slice.service";

function ProgressBarSnackbar({
  index,
  setIndex,
  currentCategory,
  setCurrentCategory,
  progressIndicator,
  setProgressIndicator,
  maxProgressIndicator,
  setMaxProgressIndicator,
  counter,
  setCounter,
  steps,
  stopCounter,
  setStopCounter,
}: {
  index: number;
  setIndex: Function;
  currentCategory: string;
  setCurrentCategory: Function;
  progressIndicator: number;
  setProgressIndicator: Function;
  maxProgressIndicator: number;
  setMaxProgressIndicator: Function;
  counter: number;
  setCounter: Function;
  steps: {
    category: string;
    title: string;
    pending: string;
    completed: string;
    duration: number;
  }[];
  stopCounter: boolean;
  setStopCounter: Function;
}) {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(true);
  const { getAIPhotosByUUIDObj } = useAppSelector(photoSelector);

  const pingAPI = () => {
    const uuid: string = Cookies.get("remote_uuid") as string;
    if (uuid) {
      dispatch(getAIPhotosByUUID({ uuid })).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          if (
            currentCategory === "hiking" &&
            payload.data.categorised_remote_images[1].completed_at &&
            payload.data.categorised_remote_images[1].photos.length > 0
          ) {
            setMaxProgressIndicator(100);
          } else if (
            currentCategory === "rockstar" &&
            payload.data.categorised_remote_images[2].completed_at &&
            payload.data.categorised_remote_images[2].photos.length > 0
          ) {
            setMaxProgressIndicator(100);
          } else if (
            currentCategory === "holding_puppy" &&
            payload.data.categorised_remote_images[3].completed_at &&
            payload.data.categorised_remote_images[3].photos.length > 0
          ) {
            setMaxProgressIndicator(100);
            setStopCounter(true);
          } else {
            setCounter(maxProgressIndicator / steps[index].duration);
          }
        }
      });
    }
  };

  const progressUpdate = () => {
    if (counter < 900) {
      setCounter(counter + maxProgressIndicator / steps[index].duration);
      if (maxProgressIndicator > 95) {
        setProgressIndicator(
          progressIndicator + maxProgressIndicator / steps[index].duration
        );
        if (progressIndicator >= 100) {
          if (stopCounter) {
            setCounter(900);
            dispatch(
              setReduxStoreHoldingPuppyPhotos(
                getAIPhotosByUUIDObj.data.categorised_remote_images[3].photos
              )
            );
            setShowSnackbar(false);
          } else {
            let tempIndex = 1;
            if (currentCategory === "hiking") {
              setCurrentCategory("rockstar");
              dispatch(
                setReduxStoreHikingPhotos(
                  getAIPhotosByUUIDObj.data.categorised_remote_images[1].photos
                )
              );
            } else if (currentCategory === "rockstar") {
              tempIndex = 2;
              setCurrentCategory("holding_puppy");
              dispatch(
                setReduxStoreRockstarPhotos(
                  getAIPhotosByUUIDObj.data.categorised_remote_images[2].photos
                )
              );
            }
            setMaxProgressIndicator(95);
            setIndex(tempIndex);
            setCounter(95 / steps[tempIndex].duration);
            setProgressIndicator(95 / steps[tempIndex].duration);
          }
        }
      } else {
        if (counter >= progressIndicator) {
          setProgressIndicator(
            progressIndicator + maxProgressIndicator / steps[index].duration
          );
        }

        if (counter >= 80) {
          pingAPI();
        }
      }
    }
  };

  IntervalHooks.useDispatch(() => {
    progressUpdate();
  }, 1000);

  return (
    <>
      {counter < 900 && (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={showSnackbar}
            sx={{
              background: "#F8F3FA",
              boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.25)",
              border: "3px solid #E8E8E8",
              borderRadius: "15px",
              width: { xs: "320px", md: "400px" },
              marginLeft: { xs: "20px", sm: 0 },
            }}
          >
            <Box sx={{ width: "100%", padding: "16px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4">{steps[index].title}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginTop: "8px", marginBottom: "8px" }}
                >
                  <ProgressBar
                    completed={progressIndicator}
                    isLabelVisible={false}
                    baseBgColor="rgba(211, 129, 240, 0.15)"
                    bgColor="#C957F1"
                    height="10px"
                  />
                </Grid>
                {progressIndicator >= 100 ? (
                  <>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            sx={{ color: "rgba(4, 2, 5, 0.70)" }}
                          >
                            {steps[index].completed}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={10}>
                          <Typography
                            variant="h5"
                            sx={{ color: "rgba(4, 2, 5, 0.70)" }}
                          >
                            {steps[index].pending}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Grid container justifyContent="flex-end">
                            <Typography
                              variant="h5"
                              sx={{ color: "rgba(4, 2, 5, 0.70)" }}
                            >
                              {progressIndicator >= 100
                                ? 100
                                : Math.floor(progressIndicator)}
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Snackbar>
        </>
      )}
    </>
  );
}

export default ProgressBarSnackbar;
