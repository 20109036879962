import { Box, useMediaQuery, useTheme, IconButton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ExpandIcon from "../../../../fixtures/icons/expand.icon";

function Photo({
  item,
  setOpenModal,
  setSelectedPhoto,
}: {
  item: { id: number; remote_url: string };
  setOpenModal: Function;
  setSelectedPhoto: Function;
}) {
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <LazyLoadImage
        effect="blur"
        src={item.remote_url}
        alt={item.remote_url}
        style={{
          width: belowBreakPoint ? "146px" : "233px",
          height: belowBreakPoint ? "210.142px" : "292px",
          borderRadius: "25px",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          borderRadius: "25px",
          transition: ".5s ease",
          background: "rgba(0, 0, 0, 0.20)",
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        <Box sx={{ position: "absolute", top: 10, right: 10 }}>
          <IconButton
            sx={{
              background: "rgba(0, 0, 0, 0.35)",
              borderRadius: "12px",
            }}
            onClick={() => {
              setOpenModal(true);
              setSelectedPhoto(item.remote_url);
            }}
          >
            <ExpandIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default Photo;
