import { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import {
  getAIPhotosByUUID,
  photoSelector,
  setReduxStoreBusinessPhotos,
  setReduxStoreHikingPhotos,
  setReduxStoreRockstarPhotos,
  setReduxStoreHoldingPuppyPhotos,
  removePreviousValidations,
} from "../../../store/photo/photo-slice.service";

import HeaderContent from "../../../infrastructure/layout/components/header-content.component";

import GeneratedPhoto from "../components/generated-photos/generated-photo.component";
import GeneratedPhotoModal from "../components/generated-photos/generated-photo-modal.component";
import ProgressBarSnackbar from "../components/generated-photos/progress-bar-snackbar.component";

import routes from "../../../infrastructure/navigation/routes";

import Spacer from "../../../components/utils/spacer.component";
import CtaButton from "../../../components/utils/cta-button.component";
import ContentContainer from "../../../components/utils/content-container.component";

function GeneratedPhotosScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [disableButton] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string>("");
  const {
    reduxStoreBusinessPhotos,
    reduxStoreHikingPhotos,
    reduxStoreRockstarPhotos,
    reduxStoreHoldingPuppyPhotos,
  } = useAppSelector(photoSelector);
  const steps: {
    category: string;
    title: string;
    pending: string;
    completed: string;
    duration: number;
  }[] = [
    {
      category: "hiking",
      title: "Hiking pics",
      pending: "In progress . . .",
      completed: "My tail's wagging for hike!",
      duration: 240,
    },
    {
      category: "rockstar",
      title: "Rockstar pics",
      pending: "In progress . . .",
      completed: "Ready to steal the show!",
      duration: 240,
    },
    {
      category: "holding_puppy",
      title: "Holding puppy pics",
      pending: "In progress . . .",
      completed: "Pics are ready!",
      duration: 240,
    },
  ];
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  const [currentCategory, setCurrentCategory] = useState("hiking");
  const [maxProgressIndicator, setMaxProgressIndicator] = useState(95);
  const [progressIndicator, setProgressIndicator] = useState(
    index === 0 ? maxProgressIndicator / steps[index].duration : 0
  );
  const [counter, setCounter] = useState(
    index === 0 ? maxProgressIndicator / steps[index].duration : 0
  );
  const [stopCounter, setStopCounter] = useState(false);

  useEffect(() => {
    function firstPingAPI() {
      const uuid: string = Cookies.get("remote_uuid") as string;
      if (uuid) {
        dispatch(getAIPhotosByUUID({ uuid })).then(({ meta, payload }) => {
          if (meta.requestStatus === "fulfilled") {
            if (payload.data.status === "failed") {
              navigate(routes.PHOTO_POST_UPLOADED);
            } else {
              if (
                payload.data.status === "pending" &&
                payload.data.categorised_remote_images.length === 0
              ) {
                navigate(routes.PHOTO_POST_UPLOADED);
              } else {
                if (
                  payload.data.categorised_remote_images[0].photos.length > 0
                ) {
                  dispatch(
                    setReduxStoreBusinessPhotos(
                      payload.data.categorised_remote_images[0].photos
                    )
                  );
                }

                if (
                  payload.data.categorised_remote_images[1].photos.length > 0
                ) {
                  dispatch(
                    setReduxStoreHikingPhotos(
                      payload.data.categorised_remote_images[1].photos
                    )
                  );
                }

                if (
                  payload.data.categorised_remote_images[2].photos.length > 0
                ) {
                  dispatch(
                    setReduxStoreRockstarPhotos(
                      payload.data.categorised_remote_images[2].photos
                    )
                  );
                }

                if (
                  payload.data.categorised_remote_images[3].photos.length > 0
                ) {
                  dispatch(
                    setReduxStoreHoldingPuppyPhotos(
                      payload.data.categorised_remote_images[3].photos
                    )
                  );
                }

                if (
                  payload.data.categorised_remote_images[3].photos.length > 0
                ) {
                  setCurrentCategory("holding_puppy");
                  setIndex(2);
                  setProgressIndicator(100);
                  setCounter(900);
                } else if (
                  payload.data.categorised_remote_images[2].photos.length > 0
                ) {
                  setCurrentCategory("holding_puppy");
                  setIndex(2);
                } else if (
                  payload.data.categorised_remote_images[1].photos.length > 0
                ) {
                  setCurrentCategory("rockstar");
                  setIndex(1);
                }
              }
            }
          }
        });
      } else {
        navigate(routes.PHOTO_UPLOAD);
      }
    }
    firstPingAPI();
  }, [dispatch, navigate]);

  const handleBack = (): void => {
    localStorage.clear();
    Cookies.remove("remote_uuid");
    dispatch(removePreviousValidations());
    navigate(routes.PHOTO_UPLOAD);
  };

  return (
    <>
      <ContentContainer>
        <HeaderContent
          hideBackBtn={
            reduxStoreBusinessPhotos.length === 0 ||
            reduxStoreHikingPhotos.length === 0 ||
            reduxStoreRockstarPhotos.length === 0 ||
            reduxStoreHoldingPuppyPhotos.length === 0
          }
          handleBack={handleBack}
          disabledCreditBtn={true}
        />
        <Spacer position="top" size="xl" />
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ margin: "0px 24px" }}>
            <Grid
              container
              sx={{
                borderRadius: "50px",
                borderTop: "4px solid #E8E8E8",
                borderRight: "4px solid #E8E8E8",
                borderLeft: "4px solid #E8E8E8",
                background: "#F8F3FA",
                minHeight: "calc(100vh - 80px)",
                padding: "16px",
              }}
            >
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    sx={{
                      marginTop: belowBreakPoint ? "24px" : "60px",
                      marginBottom: "36px",
                    }}
                  >
                    <Typography variant="banner">
                      Look at the new you!
                    </Typography>
                  </Grid>

                  {reduxStoreBusinessPhotos.length > 0 && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreBusinessPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                BUSINESS
                              </Typography>
                            )}
                            <GeneratedPhoto
                              key={photo.id}
                              remote_url={photo.remote_url}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {reduxStoreHikingPhotos.length > 0 && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreHikingPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                HIKING
                              </Typography>
                            )}
                            <GeneratedPhoto
                              key={photo.id}
                              remote_url={photo.remote_url}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {reduxStoreRockstarPhotos.length > 0 && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreRockstarPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                ROCKSTAR
                              </Typography>
                            )}
                            <GeneratedPhoto
                              key={photo.id}
                              remote_url={photo.remote_url}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {reduxStoreHoldingPuppyPhotos.length > 0 && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreHoldingPuppyPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                HOLDING PUPPY
                              </Typography>
                            )}
                            <GeneratedPhoto
                              key={photo.id}
                              remote_url={photo.remote_url}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                  {reduxStoreBusinessPhotos.length > 0 &&
                    reduxStoreHikingPhotos.length > 0 &&
                    reduxStoreRockstarPhotos.length > 0 &&
                    reduxStoreHoldingPuppyPhotos.length > 0 && (
                      <Grid item xs={12} sx={{ padding: "16px" }}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          sx={{ marginBottom: "24px" }}
                        >
                          <Grid item>
                            <CtaButton
                              background="#101010"
                              text="Generate More"
                              disabled={
                                reduxStoreBusinessPhotos.length === 0 ||
                                reduxStoreHikingPhotos.length === 0 ||
                                reduxStoreRockstarPhotos.length === 0 ||
                                reduxStoreHoldingPuppyPhotos.length === 0 ||
                                disableButton
                              }
                              onClick={() => {
                                dispatch(removePreviousValidations());
                                Cookies.remove("remote_uuid");
                                localStorage.clear();
                                navigate(routes.PHOTO_UPLOAD);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Spacer position="top" size="xxl" />
        <GeneratedPhotoModal
          remote_url={selectedPhoto}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
        <ProgressBarSnackbar
          index={index}
          setIndex={setIndex}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          progressIndicator={progressIndicator}
          setProgressIndicator={setProgressIndicator}
          maxProgressIndicator={maxProgressIndicator}
          setMaxProgressIndicator={setMaxProgressIndicator}
          counter={counter}
          setCounter={setCounter}
          steps={steps}
          stopCounter={stopCounter}
          setStopCounter={setStopCounter}
        />
      </ContentContainer>
    </>
  );
}

export default GeneratedPhotosScreen;
