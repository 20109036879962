import { Grid, Typography } from "@mui/material";

function GeneratingStatus() {
  return (
    <>
      <Grid
        item
        sx={{
          borderRadius: "50px",
          background: "rgba(211, 129, 240, 0.15)",
          padding: "8px 20px",
        }}
      >
        <Typography
          sx={{
            color: "#D381F0",
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px",
          }}
        >
          Generating
        </Typography>
      </Grid>
    </>
  );
}

export default GeneratingStatus;
