import { useTheme, useMediaQuery } from "@mui/material";

function ErrorIcon() {
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {belowBreakPoint ? (
        <>
          <svg width="111" height="90" viewBox="0 0 111 90" fill="none">
            <g filter="url(#filter0_di_875_493)">
              <path
                d="M17.6969 67.1619C16.143 69.9657 13.1247 71.5889 11.8099 72.05C14.6204 72.2228 21.4709 72.2848 26.3886 71.1509C30.4963 70.2039 33.2192 67.9382 34.5012 66.419C34.8936 65.9539 35.4677 65.6583 36.0707 65.7399C40.4271 66.3292 53.5271 68.71 63.6618 71.5144C77.5091 75.3461 89.1197 73.0783 95.3617 67.9994C102.744 61.9927 107.401 45.1418 103.965 32.2291C100.528 19.3165 88.271 16.0567 84.8575 14.8647C81.4439 13.6726 52.0687 7.42349 38.3441 6.54609C24.6196 5.66868 15.4993 8.12926 12.1085 18.6578C8.71766 29.1863 3.45893 42.7468 7.24789 51.5828C9.93128 57.8406 14.9433 60.4085 17.8774 61.1212C18.4953 61.2713 19.03 61.7059 19.0441 62.3417C19.0688 63.452 18.8001 65.1716 17.6969 67.1619Z"
                fill="#F92F6C"
              />
              <path
                d="M17.6969 67.1619C16.143 69.9657 13.1247 71.5889 11.8099 72.05C14.6204 72.2228 21.4709 72.2848 26.3886 71.1509C30.4963 70.2039 33.2192 67.9382 34.5012 66.419C34.8936 65.9539 35.4677 65.6583 36.0707 65.7399C40.4271 66.3292 53.5271 68.71 63.6618 71.5144C77.5091 75.3461 89.1197 73.0783 95.3617 67.9994C102.744 61.9927 107.401 45.1418 103.965 32.2291C100.528 19.3165 88.271 16.0567 84.8575 14.8647C81.4439 13.6726 52.0687 7.42349 38.3441 6.54609C24.6196 5.66868 15.4993 8.12926 12.1085 18.6578C8.71766 29.1863 3.45893 42.7468 7.24789 51.5828C9.93128 57.8406 14.9433 60.4085 17.8774 61.1212C18.4953 61.2713 19.03 61.7059 19.0441 62.3417C19.0688 63.452 18.8001 65.1716 17.6969 67.1619Z"
                fill="#F8F3FA"
              />
            </g>
            <g clipPath="url(#clip0_875_493)">
              <path
                d="M55.7943 47.3856C56.1682 47.4627 56.508 47.4007 56.8137 47.1995C57.1194 46.9983 57.3102 46.7111 57.3863 46.3379C57.4634 45.9639 57.4013 45.6241 57.2002 45.3185C56.999 45.0128 56.7118 44.822 56.3385 44.7459C55.9646 44.6688 55.6248 44.7308 55.3191 44.932C55.0135 45.1332 54.8226 45.4204 54.7466 45.7936C54.6695 46.1676 54.7315 46.5074 54.9327 46.8131C55.1338 47.1187 55.421 47.3096 55.7943 47.3856ZM55.5629 41.8341L58.2026 42.3784L59.8354 34.4593L57.1957 33.915L55.5629 41.8341ZM54.4337 53.9848C52.6079 53.6084 50.9636 52.9079 49.5007 51.8835C48.0379 50.859 46.8349 49.6309 45.8917 48.199C44.9488 46.7662 44.2966 45.1755 43.9352 43.427C43.5738 41.6784 43.5809 39.8911 43.9565 38.0651C44.3329 36.2393 45.0334 34.595 46.0578 33.1322C47.0822 31.6693 48.3104 30.4663 49.7423 29.5232C51.1751 28.5803 52.7658 27.9281 54.5143 27.5667C56.2629 27.2053 58.0502 27.2124 59.8762 27.588C61.7019 27.9644 63.3463 28.6648 64.8091 29.6893C66.272 30.7137 67.475 31.9419 68.4181 33.3738C69.361 34.8066 70.0136 36.3973 70.3759 38.1461C70.7382 39.8949 70.7307 41.682 70.3533 43.5076C69.9769 45.3334 69.2765 46.9777 68.252 48.4406C67.2276 49.9034 65.9994 51.1064 64.5675 52.0496C63.1347 52.9925 61.544 53.6451 59.7952 54.0074C58.0464 54.3697 56.2593 54.3621 54.4337 53.9848Z"
                fill="#F92F6C"
              />
            </g>
            <defs>
              <filter
                id="filter0_di_875_493"
                x="0.220695"
                y="0.671623"
                width="110.607"
                height="78.4766"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="2.85547" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.976471 0 0 0 0 0.184314 0 0 0 0 0.421961 0 0 0 0.3 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_875_493"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_875_493"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="6.24635" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.976471 0 0 0 0 0.184314 0 0 0 0 0.421961 0 0 0 0.5 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect2_innerShadow_875_493"
                />
              </filter>
              <clipPath id="clip0_875_493">
                <rect
                  width="32.3425"
                  height="32.3425"
                  fill="white"
                  transform="translate(44.5823 21.6828) rotate(11.65)"
                />
              </clipPath>
            </defs>
          </svg>
        </>
      ) : (
        <>
          <svg width="136" height="110" viewBox="0 0 136 110" fill="none">
            <g id="Error">
              <g id="error">
                <g id="Group 20531">
                  <g id="Vector 224" filter="url(#filter0_di_869_89)">
                    <path
                      d="M21.6232 82.0623C19.7245 85.4882 16.0367 87.4715 14.4301 88.0349C17.8642 88.2459 26.2345 88.3217 32.2433 86.9363C37.2622 85.7791 40.5892 83.0108 42.1556 81.1545C42.6351 80.5863 43.3366 80.2251 44.0733 80.3248C49.3963 81.0449 65.4026 83.9538 77.7858 87.3804C94.7052 92.0622 108.892 89.2913 116.519 83.0856C125.538 75.7462 131.229 55.1568 127.03 39.3794C122.832 23.602 107.855 19.619 103.684 18.1625C99.513 16.706 63.6206 9.07045 46.8512 7.99839C30.0818 6.92633 18.938 9.9328 14.7949 22.7972C10.6518 35.6616 4.22642 52.2306 8.856 63.0269C12.1347 70.673 18.2587 73.8106 21.8437 74.6814C22.5988 74.8648 23.2521 75.3958 23.2693 76.1727C23.2994 77.5293 22.9711 79.6304 21.6232 82.0623Z"
                      fill="#F92F6C"
                    />
                    <path
                      d="M21.6232 82.0623C19.7245 85.4882 16.0367 87.4715 14.4301 88.0349C17.8642 88.2459 26.2345 88.3217 32.2433 86.9363C37.2622 85.7791 40.5892 83.0108 42.1556 81.1545C42.6351 80.5863 43.3366 80.2251 44.0733 80.3248C49.3963 81.0449 65.4026 83.9538 77.7858 87.3804C94.7052 92.0622 108.892 89.2913 116.519 83.0856C125.538 75.7462 131.229 55.1568 127.03 39.3794C122.832 23.602 107.855 19.619 103.684 18.1625C99.513 16.706 63.6206 9.07045 46.8512 7.99839C30.0818 6.92633 18.938 9.9328 14.7949 22.7972C10.6518 35.6616 4.22642 52.2306 8.856 63.0269C12.1347 70.673 18.2587 73.8106 21.8437 74.6814C22.5988 74.8648 23.2521 75.3958 23.2693 76.1727C23.2994 77.5293 22.9711 79.6304 21.6232 82.0623Z"
                      fill="#F8F3FA"
                    />
                  </g>
                </g>
                <path
                  id="Vector"
                  d="M68.1727 57.8987C68.6296 57.9929 69.0448 57.9171 69.4183 57.6713C69.7918 57.4255 70.0249 57.0746 70.1179 56.6185C70.2121 56.1616 70.1363 55.7464 69.8905 55.3729C69.6447 54.9995 69.2938 54.7663 68.8377 54.6734C68.3808 54.5792 67.9656 54.6549 67.5921 54.9007C67.2186 55.1465 66.9854 55.4975 66.8925 55.9535C66.7983 56.4104 66.8741 56.8256 67.1199 57.1991C67.3657 57.5726 67.7166 57.8058 68.1727 57.8987ZM67.89 51.1155L71.1154 51.7805L73.1103 42.1046L69.885 41.4396L67.89 51.1155ZM66.5102 65.962C64.2794 65.502 62.2702 64.6462 60.4828 63.3945C58.6954 62.1427 57.2256 60.6421 56.0732 58.8925C54.921 57.1419 54.1242 55.1983 53.6826 53.0618C53.241 50.9253 53.2497 48.7415 53.7086 46.5104C54.1685 44.2795 55.0244 42.2704 56.2761 40.483C57.5278 38.6956 59.0285 37.2257 60.778 36.0734C62.5287 34.9212 64.4723 34.1244 66.6088 33.6828C68.7453 33.2412 70.9291 33.2499 73.1602 33.7088C75.391 34.1687 77.4001 35.0246 79.1875 36.2763C80.9749 37.528 82.4448 39.0287 83.5972 40.7782C84.7493 42.5289 85.5467 44.4726 85.9894 46.6093C86.432 48.7461 86.4228 50.9297 85.9618 53.1604C85.5018 55.3912 84.646 57.4003 83.3943 59.1877C82.1426 60.9751 80.6419 62.445 78.8923 63.5974C77.1417 64.7495 75.198 65.5469 73.0613 65.9896C70.9245 66.4322 68.7408 66.423 66.5102 65.962Z"
                  fill="#F92F6C"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_di_869_89"
                x="0.269839"
                y="0.820864"
                width="135.146"
                height="95.8871"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.48898" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.976471 0 0 0 0 0.184314 0 0 0 0 0.421961 0 0 0 0.3 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_869_89"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_869_89"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="7.63215" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.976471 0 0 0 0 0.184314 0 0 0 0 0.421961 0 0 0 0.5 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect2_innerShadow_869_89"
                />
              </filter>
            </defs>
          </svg>
        </>
      )}
    </>
  );
}

export default ErrorIcon;
