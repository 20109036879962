import { Button, Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";

const defaultProps = {
  disabled: false,
  loading: false,
  textColour: "#FFF",
  background:
    "radial-gradient(50.46% 66.91% at 49.77% 100.00%, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.00) 100%), #C957F1",
};

function CtaButton({
  text,
  textColour,
  onClick,
  disabled,
  loading,
  background,
}: {
  text: string;
  textColour: string;
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
  background: string;
} & typeof defaultProps) {
  return (
    <Button
      disableRipple
      disabled={disabled}
      variant="cta"
      onClick={onClick}
      style={{
        width: "100%",
        background,
        height: "60px",
      }}
    >
      {loading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#b8b8b8"
          ariaLabel="three-dots-loading"
          visible={true}
        />
      ) : (
        <Typography variant="ctaButton" sx={{ color: textColour }}>
          {text}
        </Typography>
      )}
    </Button>
  );
}

CtaButton.defaultProps = defaultProps;

export default CtaButton;
