import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import Cookies from "js-cookie";

import { useAppSelector } from "../../../../store/store";
import { photoSelector } from "../../../../store/photo/photo-slice.service";

import CtaButton from "../../../../components/utils/cta-button.component";

import ExclamationIcon from "../../../../fixtures/icons/exclamation.icon";

import PhotoUploadPreview from "./photo-upload-preview.component";

function PhotoUploadIllustrations({
  photoValidator,
  setPhotoValidator,
}: {
  photoValidator: {
    index: number;
    loading: boolean;
    success: boolean | null;
    error: string | null;
    photo: string | null;
  }[];
  setPhotoValidator: Function;
}) {
  const { uploadPhotosObj, validatePhotoObj, reduxStoreFirstRender } =
    useAppSelector(photoSelector);
  const { handleSubmit, values, setFieldValue } = useFormikContext<{
    photos: string[];
  }>();

  const populatePreviousPhotos = () => {
    if (reduxStoreFirstRender) {
      const photos: string[] = [];
      const recordedIndexes = localStorage.getItem("indexes") as string;
      if (recordedIndexes && recordedIndexes.length > 0) {
        const indexes: number[] = recordedIndexes
          .split(",")
          .map((index) => parseInt(index))
          .sort();
        indexes.forEach((index) => {
          const storage = localStorage.getItem(`photo-${index}`) as string;
          photos.push(JSON.parse(storage).remote_url);
        });
      }
      setFieldValue("photos", photos);
    }
  };

  const populatePreviousValidation = () => {
    if (reduxStoreFirstRender) {
      let failedIndexes: number[] = [];
      const failedRecordedIndexes = localStorage.getItem(
        "failed-indexes"
      ) as string;
      if (failedRecordedIndexes && failedRecordedIndexes.length > 0) {
        failedIndexes = failedRecordedIndexes
          .split(",")
          .map((failedIndex) => parseInt(failedIndex))
          .sort();
        failedIndexes.forEach((failedIndex) => {
          const storage = localStorage.getItem(
            `photo-${failedIndex}`
          ) as string;
          photoValidator[failedIndex].success = null;
          photoValidator[failedIndex].loading = false;
          photoValidator[failedIndex].error = JSON.parse(storage).reason;
        });
      }

      let indexes: number[] = [];
      const recordedIndexes = localStorage.getItem("indexes") as string;
      if (recordedIndexes && recordedIndexes.length > 0) {
        indexes = recordedIndexes
          .split(",")
          .map((index) => parseInt(index))
          .filter((index) => !failedIndexes.includes(index))
          .sort();
        indexes.forEach((index) => {
          photoValidator[index].success = true;
        });
      }
    }
  };

  useEffect(() => {
    populatePreviousPhotos();
    populatePreviousValidation();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          borderRadius: "50px 50px 0px 0px",
          borderTop: "4px solid #E8E8E8",
          borderRight: "4px solid #E8E8E8",
          borderLeft: "4px solid #E8E8E8",
          background: "#F8F3FA",
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            alignSelf: { md: "center" },
          }}
        >
          <Grid container justifyContent="center" spacing={4}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: { xs: 8 },
              }}
            >
              <Typography variant="h4" textAlign="center">
                Let's get a look at you head-on!
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid
                container
                sx={{
                  justifyContent: "center",
                }}
              >
                {[...Array(7).keys()].map((index) => (
                  <Grid item key={`${index}`}>
                    <PhotoUploadPreview
                      index={index}
                      numberOfUploads={7}
                      photoValidator={photoValidator}
                      setPhotoValidator={setPhotoValidator}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {photoValidator.filter(
              (validator: {
                index: number;
                loading: boolean;
                success: boolean | null;
                error: string | null;
                photo: string | null;
              }) => validator.error !== null
            ).length > 0 && (
              <>
                <Grid item xs={10}>
                  <Grid
                    container
                    sx={{
                      background: "rgba(249, 47, 108, 0.15)",
                      borderRadius: "15px",
                      padding: "16px",
                    }}
                  >
                    {photoValidator
                      .filter(
                        (validator: {
                          index: number;
                          loading: boolean;
                          success: boolean | null;
                          error: string | null;
                          photo: string | null;
                        }) => validator.error !== null
                      )
                      .map(
                        (validator: {
                          index: number;
                          loading: boolean;
                          success: boolean | null;
                          error: string | null;
                          photo: string | null;
                        }) => (
                          <Grid container key={`${validator.index}`}>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                marginTop: "4px",
                              }}
                            >
                              <ExclamationIcon />
                            </Grid>
                            <Grid item xs={11}>
                              <Typography sx={{ color: "#F92F6C" }}>
                                Photo {validator.index + 1}: {validator.error}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      )}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  marginBottom: 8,
                }}
              >
                <Grid item xs={8}>
                  <CtaButton
                    loading={uploadPhotosObj.status === "pending"}
                    disabled={
                      photoValidator.filter(
                        (validator: {
                          index: number;
                          loading: boolean;
                          success: boolean | null;
                          error: string | null;
                          photo: string | null;
                        }) => validator.error !== null || validator.loading
                      ).length > 0 ||
                      uploadPhotosObj.status === "pending" ||
                      validatePhotoObj.status === "pending" ||
                      values.photos.length !== 7 ||
                      Cookies.get("credit_balance") === "0"
                    }
                    onClick={handleSubmit}
                    text="Next"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PhotoUploadIllustrations;
