import Cookies from "js-cookie";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { AppBar, Box, Button, Toolbar, Typography, Link } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../store/store";
import { authSelector } from "../../../store/auth/auth-slice.service";

import Spacer from "../../../components/utils/spacer.component";

import BackArrowIcon from "../../../fixtures/icons/back-arrow.icon";

import routes from "../../navigation/routes";

const defaultProps = {
  hideBackBtn: false,
  disabledCreditBtn: false,
  handleBack: () => {},
};

function HeaderContent({
  handleBack,
  hideBackBtn,
  disabledCreditBtn,
}: {
  handleBack: () => void;
  hideBackBtn: boolean;
  disabledCreditBtn: boolean;
} & typeof defaultProps) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useAppSelector(authSelector);
  const customMinWidth1055px = useMediaQuery("@media (min-width: 1055px");

  return (
    <>
      {belowBreakPoint ? (
        <>
          <AppBar
            position="static"
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Toolbar>
              <Grid container sx={{ position: "relative" }}>
                {location.pathname === routes.HOME ||
                location.pathname === routes.SIGN_UP ? (
                  <>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Link href={routes.HOME}>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Box
                                  component="div"
                                  sx={{
                                    background:
                                      "url('/images/corgi/corgi-shaded-1.png')",
                                    height: "50px",
                                    width: "50px",
                                    flexShrink: 0,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Link>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: -2 }}>
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent="center"
                            >
                              <Typography variant="logo">Dapper AI</Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent="center"
                              sx={{ marginTop: -0.5 }}
                            >
                              <Typography variant="sublogo">
                                by DOWN - GPT Photos
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      {!hideBackBtn ? (
                        <Grid
                          container
                          alignItems="center"
                          sx={{ height: "100%" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={handleBack}
                              sx={{
                                padding: "12px 24px",
                              }}
                            >
                              <BackArrowIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ height: "100%" }}
                          >
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={() => navigate(routes.PHOTO_SETS)}
                                sx={{
                                  fontSize: "14px",
                                  padding: "12px 16px",
                                }}
                              >
                                My AI Photos
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Link href={routes.HOME}>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            background:
                              "url('/images/corgi/corgi-shaded-1.png')",
                            height: "45px",
                            width: "45px",
                            flexShrink: 0,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          transform: "translate(-50%, 20%)",
                        }}
                      >
                        <Typography variant="logo">Dapper AI</Typography>
                      </Box>
                    </Link>
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            disabled={disabledCreditBtn}
                            onClick={() => navigate(routes.ORDER_PRODUCT)}
                            sx={{
                              fontSize: "14px",
                              padding: "12px 16px",
                            }}
                          >
                            Credits:{" "}
                            {Cookies.get("credit_balance")
                              ? Cookies.get("credit_balance")
                              : user.credit_balance}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        </>
      ) : (
        <>
          <AppBar
            position="static"
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Toolbar>
              <Grid container>
                {location.pathname === routes.HOME ||
                location.pathname === routes.SIGN_UP ? (
                  <>
                    <Grid item xs={12}>
                      <Link href={routes.HOME}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Box
                                  component="div"
                                  sx={{
                                    background:
                                      "url('/images/corgi/corgi-shaded-1.png')",
                                    height: "50px",
                                    width: "50px",
                                    flexShrink: 0,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: -2.5 }}>
                            <Grid container justifyContent="center">
                              <Grid
                                item
                                container
                                xs={12}
                                justifyContent="center"
                              >
                                <Typography variant="logo">
                                  Dapper AI
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                                justifyContent="center"
                                sx={{ marginTop: -0.5 }}
                              >
                                <Typography variant="sublogo">
                                  by DOWN - GPT Photos
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Link>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      {!hideBackBtn ? (
                        <Grid
                          container
                          alignItems="center"
                          sx={{ height: "100%" }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleBack}
                            sx={{ padding: "12px 24px" }}
                          >
                            <BackArrowIcon />
                            <Spacer position="right" size="xs" />
                            Back
                          </Button>
                        </Grid>
                      ) : (
                        <>
                          {!customMinWidth1055px && (
                            <Grid
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                              sx={{ height: "100%" }}
                            >
                              <Grid item>
                                <Button
                                  variant="contained"
                                  sx={{
                                    padding: "12px 16px",
                                  }}
                                  onClick={() => navigate(routes.PHOTO_SETS)}
                                >
                                  My AI Photos
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                    <Link href={routes.HOME}>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: "50%",
                          transform: "translate(-50%, 10%)",
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            background:
                              "url('/images/corgi/corgi-shaded-1.png')",
                            height: "72px",
                            width: "72px",
                            flexShrink: 0,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          transform: "translate(-50%, -20%)",
                        }}
                      >
                        <Typography variant="logo">Dapper AI</Typography>
                      </Box>
                    </Link>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        <Grid container justifyContent="flex-end">
                          <Box
                            item
                            component={Grid}
                            display={{
                              xs: "none",
                              sm: "none",
                              md: "block",
                            }}
                          >
                            {customMinWidth1055px && (
                              <Button
                                variant="contained"
                                onClick={() => navigate(routes.PHOTO_SETS)}
                                sx={{
                                  marginRight: "12px",
                                  padding: "12px 24px",
                                }}
                              >
                                My AI Photos
                              </Button>
                            )}

                            <Button
                              variant="contained"
                              disabled={disabledCreditBtn}
                              sx={{ padding: "12px 24px" }}
                              onClick={() => navigate(routes.ORDER_PRODUCT)}
                            >
                              Credits:{" "}
                              {Cookies.get("credit_balance")
                                ? Cookies.get("credit_balance")
                                : user.credit_balance}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        </>
      )}
    </>
  );
}

HeaderContent.defaultProps = defaultProps;

export default HeaderContent;
