import Cookies from "js-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  getReq,
  postReq,
  postReqMultiPartFormData,
} from "../../services/api.services";
import { RootState } from "../store";

export const getPhotoSets = createAsyncThunk(
  "photos/index",
  async (payload: { page: number }) => {
    const response = await getReq(
      `/private/api/v2/image_requests?page=${payload.page}`
    );
    return response;
  }
);

export const uploadPhotos = createAsyncThunk(
  "photos/upload",
  async (payload: { photos: string[] }) => {
    const response = await postReq("/private/api/v2/image_requests", {
      photo_urls: payload.photos,
      platform: "web",
      environment: `${process.env.REACT_APP_ENV}`,
    });
    return response;
  }
);

export const getAIPhotosByUUID = createAsyncThunk(
  "photos/{uuid}",
  async (payload: { uuid: string }) => {
    const response = await getReq(
      `/private/api/v2/image_requests/${payload.uuid}`
    );
    return response;
  }
);

export const validatePhoto = createAsyncThunk(
  "photos/validate",
  async (payload: { photo: File; index: Number; platform: string }) => {
    const response = await postReqMultiPartFormData(
      "private/api/v2/image_requests/validate",
      payload
    );
    return response;
  }
);

export const removePreviousValidations = createAsyncThunk(
  "photos/remove_previous_validations",
  async () => {
    const response = await postReq(
      "private/api/v2/image_requests/remove_previous_validations",
      null
    );
    return response;
  }
);

type SliceState = {
  reduxStoreFirstRender: boolean;
  reduxStorePhotoSets: {
    id: number;
    remote_uuid: string;
    status: "pending" | "success";
    created_at: string;
    categorised_remote_images: {
      category: "business" | "hiking" | "rockstar" | "holding_puppy";
      photos: { id: number; remote_url: string }[];
    }[];
  }[];
  reduxStoreBusinessPhotos: { id: number; remote_url: string }[];
  reduxStoreHikingPhotos: { id: number; remote_url: string }[];
  reduxStoreRockstarPhotos: { id: number; remote_url: string }[];
  reduxStoreHoldingPuppyPhotos: { id: number; remote_url: string }[];
  getPhotoSetsObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
  uploadPhotosObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
  getAIPhotosByUUIDObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
  validatePhotoObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
  removePreviousValidationsObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
};

const initialState: SliceState = {
  reduxStoreFirstRender: true,
  reduxStorePhotoSets: [],
  reduxStoreBusinessPhotos: [],
  reduxStoreHikingPhotos: [],
  reduxStoreRockstarPhotos: [],
  reduxStoreHoldingPuppyPhotos: [],
  getPhotoSetsObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
  uploadPhotosObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
  getAIPhotosByUUIDObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
  validatePhotoObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
  removePreviousValidationsObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
};

export const photoSlice = createSlice({
  name: "photo",
  initialState,
  reducers: {
    setReduxStorePhotoSets: (state, action) => {
      state.reduxStorePhotoSets = action.payload;
    },
    setReduxStoreFirstRender: (state, action) => {
      state.reduxStoreFirstRender = action.payload;
    },
    setReduxStoreBusinessPhotos: (state, action) => {
      state.reduxStoreBusinessPhotos = action.payload;
    },
    setReduxStoreHikingPhotos: (state, action) => {
      state.reduxStoreHikingPhotos = action.payload;
    },
    setReduxStoreRockstarPhotos: (state, action) => {
      state.reduxStoreRockstarPhotos = action.payload;
    },
    setReduxStoreHoldingPuppyPhotos: (state, action) => {
      state.reduxStoreHoldingPuppyPhotos = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPhotoSets.pending, (state) => {
      state.getPhotoSetsObj.status = "pending";
    });
    builder.addCase(getPhotoSets.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.getPhotoSetsObj.status = "succeeded";
      state.getPhotoSetsObj.data = data;
    });
    builder.addCase(getPhotoSets.rejected, (state, action) => {
      const { message } = action.error;
      state.getPhotoSetsObj.status = "failed";
      if (!message) return;
      state.getPhotoSetsObj.errorMessage = message;
    });
    builder.addCase(uploadPhotos.pending, (state) => {
      state.uploadPhotosObj.status = "pending";
    });
    builder.addCase(uploadPhotos.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.uploadPhotosObj.status = "succeeded";
      state.uploadPhotosObj.data = data;
      Cookies.set("credit_balance", data.credit_balance);
    });
    builder.addCase(uploadPhotos.rejected, (state, action) => {
      const { message } = action.error;
      state.uploadPhotosObj.status = "failed";
      if (!message) return;
      state.uploadPhotosObj.errorMessage = message;
    });
    builder.addCase(getAIPhotosByUUID.pending, (state) => {
      state.getAIPhotosByUUIDObj.status = "pending";
    });
    builder.addCase(getAIPhotosByUUID.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.getAIPhotosByUUIDObj.status = "succeeded";
      if (!data) return;
      state.getAIPhotosByUUIDObj.data = data;
    });
    builder.addCase(getAIPhotosByUUID.rejected, (state, action) => {
      const { message } = action.error;
      state.getAIPhotosByUUIDObj.status = "failed";
      if (!message) return;
      state.getAIPhotosByUUIDObj.errorMessage = message;
    });
    builder.addCase(validatePhoto.pending, (state) => {
      state.validatePhotoObj.status = "pending";
    });
    builder.addCase(validatePhoto.fulfilled, (state, action) => {
      const { message } = action.payload;
      state.validatePhotoObj.status = "succeeded";
      state.validatePhotoObj.successMessage = message;
    });
    builder.addCase(validatePhoto.rejected, (state, action) => {
      const { message } = action.error;
      state.validatePhotoObj.status = "failed";
      if (!message) return;
      state.validatePhotoObj.errorMessage = message;
    });
    builder.addCase(removePreviousValidations.pending, (state) => {
      state.removePreviousValidationsObj.status = "pending";
    });
    builder.addCase(removePreviousValidations.fulfilled, (state, action) => {
      const { message } = action.payload;
      state.removePreviousValidationsObj.status = "succeeded";
      state.removePreviousValidationsObj.successMessage = message;
    });
    builder.addCase(removePreviousValidations.rejected, (state, action) => {
      const { message } = action.error;
      state.removePreviousValidationsObj.status = "failed";
      if (!message) return;
      state.removePreviousValidationsObj.errorMessage = message;
    });
  },
});

export default photoSlice.reducer;

export const photoSelector = (state: RootState) => state.photo;

export const {
  setReduxStorePhotoSets,
  setReduxStoreFirstRender,
  setReduxStoreBusinessPhotos,
  setReduxStoreHikingPhotos,
  setReduxStoreRockstarPhotos,
  setReduxStoreHoldingPuppyPhotos,
} = photoSlice.actions;
