import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import CircularProgress from "@mui/material/CircularProgress";

import { useAppDispatch } from "../../../../store/store";
import {
  setReduxStoreFirstRender,
  validatePhoto,
} from "../../../../store/photo/photo-slice.service";

import PlusIcon from "../../../../fixtures/icons/plus.icon";
import DeleteIcon from "../../../../fixtures/icons/delete-icon";
import ReUploadIcon from "../../../../fixtures/icons/re-upload.icon";

function PhotoUploadPreview({
  index,
  numberOfUploads,
  photoValidator,
  setPhotoValidator,
}: {
  index: number;
  numberOfUploads: number;
  photoValidator: {
    index: number;
    loading: boolean;
    success: boolean | null;
    error: string | null;
    photo: string | null;
  }[];
  setPhotoValidator: Function;
}) {
  const { values, setFieldValue } = useFormikContext<{
    photos: string[];
  }>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  const handlePhotoPreview = (index: number): string => {
    return values.photos[index];
  };

  const handlePhotoUpdate = (e: any): void => {
    if (!e.currentTarget.files) return;
    const file = e.currentTarget.files[0];
    dispatch(setReduxStoreFirstRender(false));
    const newPhotos = [...values.photos];
    newPhotos[index] = URL.createObjectURL(file);
    setFieldValue("photos", newPhotos);
    photoValidator[index].loading = true;
    photoValidator[index].error = null;
    photoValidator[index].success = null;

    dispatch(validatePhoto({ platform: "web", photo: file, index }))
      .unwrap()
      .then(({ data }) => {
        localStorage.setItem(`photo-${index}`, JSON.stringify(data));
        const recordedFailedIndexes = localStorage.getItem(
          "failed-indexes"
        ) as string;
        if (recordedFailedIndexes) {
          const arrayFailedIndexes: number[] = recordedFailedIndexes
            .split(",")
            .map((failedIndex) => parseInt(failedIndex))
            .filter((i) => i !== index)
            .sort();
          let failedIndexes = "";
          arrayFailedIndexes.forEach((failedIndex) => {
            if (failedIndexes.length === 0) {
              failedIndexes = failedIndex.toString();
            } else {
              failedIndexes += "," + failedIndex.toString();
            }
          });
          if (failedIndexes.length > 0) {
            localStorage.setItem("failed-indexes", failedIndexes);
          } else {
            localStorage.removeItem("failed-indexes");
          }
        }
        let newPhotoValidator = photoValidator;
        newPhotoValidator[index].loading = false;
        if (data["status"] === "success") {
          newPhotoValidator[index].success = true;
        } else if (data["status"] === "failed") {
          newPhotoValidator[index].success = false;
          newPhotoValidator[index].error = data.reason;
          const recordedFailedIndexes = localStorage.getItem(
            "failed-indexes"
          ) as string;
          let failedIndexes = recordedFailedIndexes
            ? recordedFailedIndexes
            : "";
          if (failedIndexes.length === 0) {
            failedIndexes = index.toString();
          } else {
            failedIndexes += "," + index.toString();
          }
          localStorage.setItem("failed-indexes", failedIndexes);
        }
        setPhotoValidator([...newPhotoValidator]);
      });
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box>
          <Button
            component="label"
            disabled={
              values.photos.length > 0 && values.photos[index] ? true : false
            }
            sx={{
              padding: { xs: "2px 4px", lg: "6px 8px" },
              backgroundColor: "transparent",
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {values.photos.length > 0 && values.photos[index] ? (
              <>
                {belowBreakPoint ? (
                  <>
                    <img
                      src={handlePhotoPreview(index)}
                      style={{
                        width: "79px",
                        height: "82px",
                        borderRadius: 12,
                      }}
                      alt={`${index}`}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={handlePhotoPreview(index)}
                      style={{
                        width: "142px",
                        height: "150px",
                        borderRadius: 12,
                      }}
                      alt={`${index}`}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <PlusIcon />
              </>
            )}
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              onChange={(e) => {
                if (!e.currentTarget.files) return;

                if (
                  e.currentTarget.files.length + values.photos.length >
                  numberOfUploads
                ) {
                  alert(`Only ${numberOfUploads} photos can be uploaded`);
                  return;
                }
                dispatch(setReduxStoreFirstRender(false));
                const files = e.currentTarget.files;
                const filesArray: File[] = [];
                for (let i = 0; i < files.length; i++) {
                  filesArray.push(files[i]);
                }
                const initialTotalPhotos = values.photos.length;
                const newPhotosArray = [...values.photos].concat(
                  filesArray.map((file) => URL.createObjectURL(file))
                );
                setFieldValue("photos", newPhotosArray);

                const recordedStringIndexes = localStorage.getItem(
                  "indexes"
                ) as string;
                let recordedIndexes: number[] = [];
                if (recordedStringIndexes) {
                  recordedIndexes = recordedStringIndexes
                    .split(",")
                    .map((index) => parseInt(index))
                    .sort();
                }
                filesArray.forEach((file: File, index: number) => {
                  const newIndex = initialTotalPhotos + index;
                  if (
                    photoValidator[newIndex].success === null &&
                    photoValidator[newIndex].photo === null &&
                    !recordedIndexes.includes(newIndex)
                  ) {
                    photoValidator[newIndex].loading = true;
                    const recordedIndexes = localStorage.getItem(
                      "indexes"
                    ) as string;
                    let indexes = recordedIndexes ? recordedIndexes : "";
                    if (indexes.length === 0) {
                      indexes = newIndex.toString();
                    } else {
                      indexes += "," + newIndex.toString();
                    }
                    localStorage.setItem("indexes", indexes);
                    dispatch(
                      validatePhoto({
                        platform: "web",
                        photo: file,
                        index: newIndex,
                      })
                    )
                      .unwrap()
                      .then(({ data }) => {
                        localStorage.setItem(
                          `photo-${newIndex}`,
                          JSON.stringify(data)
                        );
                        localStorage.setItem("remove_by", data["remove_by"]);
                        let newPhotoValidator = photoValidator;
                        newPhotoValidator[newIndex].loading = false;
                        if (data["status"] === "success") {
                          newPhotoValidator[newIndex].success = true;
                          newPhotoValidator[newIndex].error = null;
                          setPhotoValidator([...newPhotoValidator]);
                        } else if (data["status"] === "failed") {
                          newPhotoValidator[newIndex].success = false;
                          newPhotoValidator[newIndex].error = data.reason;
                          setPhotoValidator([...newPhotoValidator]);
                          const recordedFailedIndexes = localStorage.getItem(
                            "failed-indexes"
                          ) as string;
                          let failedIndexes = recordedFailedIndexes
                            ? recordedFailedIndexes
                            : "";
                          if (failedIndexes.length === 0) {
                            failedIndexes = newIndex.toString();
                          } else {
                            failedIndexes += "," + newIndex.toString();
                          }
                          localStorage.setItem("failed-indexes", failedIndexes);
                        }
                      });
                  }
                });
              }}
            />
          </Button>
        </Box>
        {values.photos.length > 0 && values.photos[index] && (
          <>
            {photoValidator[index].success && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: -4,
                    right: 0,
                  }}
                >
                  <Button
                    component="label"
                    sx={{
                      position: "relative",
                      minWidth: 0,
                      padding: 0,
                      margin: 0,
                      backgroundColor: "transparent",
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <DeleteIcon />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handlePhotoUpdate}
                    />
                  </Button>
                </Box>
              </>
            )}

            {photoValidator[index].error && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: belowBreakPoint ? "2px" : "6px",
                    left: belowBreakPoint ? "4px" : "8px",
                    width: belowBreakPoint ? "79px" : "142px",
                    height: belowBreakPoint ? "82px" : "150px",
                    borderRadius: "12px",
                    border: "2px solid #F92F6C",
                    backgroundColor: "rgba(197, 185, 192, 0.50)",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Button
                        component="label"
                        sx={{
                          position: "relative",
                          minWidth: 0,
                          padding: 0,
                          margin: 0,
                          backgroundColor: "transparent",
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <ReUploadIcon />
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={handlePhotoUpdate}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    top: belowBreakPoint ? "2px" : "6px",
                    left: belowBreakPoint ? "4px" : "8px",
                    width: belowBreakPoint ? "79px" : "142px",
                    height: belowBreakPoint ? "82px" : "150px",
                    borderRadius: "12px",
                    border: "2px solid #F92F6C",
                    backgroundColor: "rgba(197, 185, 192, 0.50)",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Button
                        component="label"
                        sx={{
                          position: "relative",
                          minWidth: 0,
                          padding: 0,
                          margin: 0,
                          backgroundColor: "transparent",
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <ReUploadIcon />
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={handlePhotoUpdate}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {photoValidator[index].loading && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: belowBreakPoint ? "2px" : "6px",
                    left: belowBreakPoint ? "4px" : "8px",
                    width: belowBreakPoint ? "79px" : "142px",
                    height: belowBreakPoint ? "82px" : "150px",
                    borderRadius: "12px",
                    backgroundColor: "rgba(197, 185, 192, 0.50)",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PhotoUploadPreview;
