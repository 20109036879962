import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  createOrder,
  orderSelector,
} from "../../../store/order/order-slice.service";

import CtaButton from "../../../components/utils/cta-button.component";

import routes from "../../../infrastructure/navigation/routes";
import HeaderContent from "../../../infrastructure/layout/components/header-content.component";

function OrderProductConfirmationScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createOrderObj } = useAppSelector(orderSelector);

  const orderProduct = () => {
    dispatch(
      createOrder({
        product_id: "price_1OrKgIIIRTRpYwgb5iKCPGTy",
        quantity: 1,
      })
    ).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        window.open(payload.data.pay_url, "_self");
      }
    });
  };

  return (
    <>
      <HeaderContent handleBack={() => navigate(routes.ORDER_PRODUCT)} />
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: "64px" }}>
          <Grid item container justifyContent="center" order={{ xs: 2, md: 1 }}>
            <Typography variant="headerLogo">Confirm Payment</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "24px" }}>
          <Grid item container justifyContent="center">
            <Box
              component="div"
              sx={{
                position: "fixed",
                top: "calc(100vh /1.8)",
                left: 0,
                height: "380px",
                filter: "blur(100.81px)",
                width: "100vw",
                transform: "rotate(23.75deg)",
                background:
                  "linear-gradient(230deg, rgba(237, 85, 158, 0.20) 0%, rgba(138, 91, 238, 0.20) 100%)",
              }}
            />
            <Grid item xs={8} md={4}>
              <Grid
                container
                sx={{
                  backgroundColor: "#FFFFFF03",
                  border: "3.5px solid rgba(214, 100, 255, 0.15)",
                  boxShadow: "0px 0px 65.75px 0px rgba(214, 98, 255, 0.25)",
                  borderRadius: "25px",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  sx={{ paddingTop: "18px" }}
                >
                  <Typography variant="price">$2.99</Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  sx={{ marginTop: "-16px" }}
                >
                  <Typography variant="h5">1 set</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "24px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={8} md={4}>
              <Grid container>
                <Typography
                  variant="h5"
                  style={{ fontSize: "16px" }}
                  sx={{ textAlign: "center" }}
                >
                  Please note: to prevent fraud, there are no refunds once your
                  photos are generated.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              container
              justifyContent="center"
              sx={{ marginTop: "24px" }}
            >
              <Grid item xs={8} md={4}>
                <CtaButton
                  text="Continue"
                  onClick={orderProduct}
                  disabled={createOrderObj.status === "pending"}
                  loading={createOrderObj.status === "pending"}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              sx={{ marginTop: "12px", marginBottom: "60px" }}
            >
              <Grid item xs={8} md={4}>
                <CtaButton
                  background="#FFF"
                  textColour="#101010"
                  text="Cancel"
                  onClick={() => navigate(routes.PHOTO_UPLOAD)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default OrderProductConfirmationScreen;
