import { Typography, Grid } from "@mui/material";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

import GeneratingStatus from "./generating-status.component";
import GeneratedStatus from "./generated-status.component";
import CtaButton from "../../../../components/utils/cta-button.component";
import routes from "../../../../infrastructure/navigation/routes";

function PhotoSet({
  item,
}: {
  item: {
    id: number;
    remote_uuid: string;
    status: "pending" | "success";
    created_at: string;
    categorised_remote_images: {
      category: "business" | "hiking" | "rockstar" | "holding_puppy";
      photos: { id: number; remote_url: string }[];
    }[];
  };
}) {
  const navigate = useNavigate();

  return (
    <>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item sx={{ alignSelf: "center" }}>
            <Typography
              sx={{
                color: "#040205",

                fontFamily: "Bai Jamjuree",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "24px",
              }}
            >
              Set #{item.id}
            </Typography>
          </Grid>
          {item.status === "pending" && <GeneratingStatus />}
          {item.status === "success" && <GeneratedStatus />}
        </Grid>
      </Grid>
      <Grid item sx={{ margin: "24px 0px" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              sx={{
                color: "rgba(4, 2, 5, 0.40)",
                fontFamily: "Bai Jamjuree",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Date created:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "#040205",
                textAlign: "right",
                fontFamily: "Bai Jamjuree",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              {moment(item.created_at).tz(moment.tz.guess()).format("LLL")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CtaButton
          text="View"
          onClick={() => {
            item.status === "pending"
              ? navigate(`${routes.PHOTO_POST_UPLOADED}`)
              : navigate(`${routes.PHOTO_SETS}/${item.remote_uuid}`, {
                  state: { item },
                });
          }}
        />
      </Grid>
    </>
  );
}

export default PhotoSet;
