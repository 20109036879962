import { useEffect } from "react";
import { Grid, useTheme, useMediaQuery, Box, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  authSelector,
  createSession,
} from "../../../store/auth/auth-slice.service";

import routes from "../../../infrastructure/navigation/routes";
import Header from "../../../infrastructure/layout/components/header.component";

import FormField from "../components/form-field.component";

import CtaButton from "../../../components/utils/cta-button.component";
import Spacer from "../../../components/utils/spacer.component";

const SignUpSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function SignUpScreen() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createSessionObj } = useAppSelector(authSelector);
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  const handleSubmit = (values: { email: string }) => {
    dispatch(createSession(values)).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.PHOTO_UPLOAD);
      }
    });
  };

  useEffect(() => {
    localStorage.clear();
  });

  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          minHeight: { lg: "calc(100vh - 150px)" },
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          lg={10}
          sx={{
            overflow: "hidden",
            borderRadius: { lg: "0px 0px 50px 50px" },
            background: {
              lg: "linear-gradient(0deg, rgba(237, 85, 158, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)",
            },
          }}
        >
          <Grid container sx={{ overflow: "hidden", height: "100%" }}>
            {!belowBreakPoint && (
              <>
                <Grid
                  item
                  lg={6}
                  sx={{
                    display: { lg: "block" },
                    height: { lg: "100%" },
                  }}
                >
                  <Grid
                    container
                    sx={{
                      height: "100%",
                      position: "relative",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        marginBottom: -4,
                        marginLeft: {
                          lg: "-10%",
                        },
                        background: "url('/images/corgi/corgi-shaded-1.png')",
                        height: {
                          lg: "400px",
                          xl: "450px",
                        },
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        transform: "rotate(-7.55deg)",
                      }}
                    />
                    <>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: { lg: 200, xl: 180 },
                          zIndex: 1,
                          left: { lg: 180, xl: 250 },
                          background: "url('/images/corgi/banner-bubble.png')",
                          backgroundSize: "contain",
                          height: {
                            lg: "190px",
                          },
                          width: {
                            lg: "300px",
                          },
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            sx={{
                              marginBottom: { xs: 4 },
                            }}
                          >
                            <Typography variant="subbanner">Sign Up</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              </>
            )}
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={SignUpSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, isValid, dirty }) => (
                <>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      sx={{
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid
                            item
                            xs={10}
                            sx={{
                              backgroundColor:
                                theme.palette.background.tertiary,
                              borderRadius: "25px",
                            }}
                          >
                            <Grid
                              container
                              sx={{ marginTop: 4, marginBottom: 4 }}
                              justifyContent="center"
                            >
                              <Grid item xs={11}>
                                <Typography
                                  variant="banner"
                                  sx={{
                                    fontSize: {
                                      xs: "28px",
                                      sm: "30px",
                                      md: "35px",
                                      lg: "40px",
                                      xl: "40px",
                                    },
                                  }}
                                >
                                  Enter your email
                                </Typography>
                              </Grid>
                              <Grid item xs={11} sx={{ marginTop: 2 }}>
                                <Typography
                                  variant="h5"
                                  sx={{ color: "rgba(0, 0, 0, 0.70)" }}
                                >
                                  Create an account using your email address
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={11}
                                sx={{ marginTop: 6, marginBottom: 6 }}
                              >
                                <FormField name="email" />
                                {createSessionObj.status === "failed" &&
                                  createSessionObj.errorMessage?.includes(
                                    "Cannot read properties of undefined (reading 'data')"
                                  ) && (
                                    <>
                                      <Spacer size="s" position="bottom" />
                                      <Typography sx={{ color: "#ff3333" }}>
                                        Our server is down, please try again
                                        later.
                                      </Typography>
                                    </>
                                  )}
                                {createSessionObj.status === "failed" &&
                                  createSessionObj.errorMessage?.includes(
                                    "Email not allowed"
                                  ) && (
                                    <>
                                      <Spacer size="s" position="bottom" />
                                      <Typography sx={{ color: "#ff3333" }}>
                                        Only for the use of Down App personnel.
                                      </Typography>
                                    </>
                                  )}
                              </Grid>
                              <Grid
                                item
                                xs={11}
                                container
                                justifyContent="center"
                              >
                                <CtaButton
                                  disabled={
                                    !(isValid && dirty) ||
                                    createSessionObj.status === "pending"
                                  }
                                  loading={
                                    createSessionObj.status === "pending"
                                  }
                                  text="Next"
                                  onClick={handleSubmit}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SignUpScreen;
