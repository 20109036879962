import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getReq, postReq, putReq } from "../../services/api.services";

export const createOrder = createAsyncThunk(
  "order/create",
  async (payload: { product_id: string; quantity: number }) => {
    const response = await postReq("/private/api/v1/orders", payload);
    return response;
  }
);

export const getOrder = createAsyncThunk(
  "order/show",
  async (payload: { order_id: string }) => {
    const response = await getReq(`/private/api/v1/orders/${payload.order_id}`);
    return response;
  }
);

export const cancelOrder = createAsyncThunk(
  "order/cancel",
  async (payload: { order_id: string }) => {
    const response = await putReq(
      `/private/api/v1/orders/${payload.order_id}/expire`,
      null
    );
    return response;
  }
);

type SliceState = {
  createOrderObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
  getOrderObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
  cancelOrderObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
};

const initialState: SliceState = {
  createOrderObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
  getOrderObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
  cancelOrderObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state) => {
      state.createOrderObj.status = "pending";
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.createOrderObj.status = "succeeded";
      state.createOrderObj.data = data;
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      const { message } = action.error;
      state.createOrderObj.status = "failed";
      if (!message) return;
      state.createOrderObj.errorMessage = message;
    });
    builder.addCase(getOrder.pending, (state) => {
      state.getOrderObj.status = "pending";
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.getOrderObj.status = "succeeded";
      state.getOrderObj.data = data;
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      const { message } = action.error;
      state.getOrderObj.status = "failed";
      if (!message) return;
      state.getOrderObj.errorMessage = message;
    });
    builder.addCase(cancelOrder.pending, (state) => {
      state.cancelOrderObj.status = "pending";
    });
    builder.addCase(cancelOrder.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.cancelOrderObj.status = "succeeded";
      state.cancelOrderObj.data = data;
    });
    builder.addCase(cancelOrder.rejected, (state, action) => {
      const { message } = action.error;
      state.cancelOrderObj.status = "failed";
      if (!message) return;
      state.cancelOrderObj.errorMessage = message;
    });
  },
});

export default orderSlice.reducer;

export const orderSelector = (state: RootState) => state.order;

// export const {} = orderSlice.actions;
