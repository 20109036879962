import { useEffect, useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  getAIPhotosByUUID,
  photoSelector,
  setReduxStoreBusinessPhotos,
  setReduxStoreHikingPhotos,
  setReduxStoreHoldingPuppyPhotos,
  setReduxStoreRockstarPhotos,
} from "../../../store/photo/photo-slice.service";

import HeaderContent from "../../../infrastructure/layout/components/header-content.component";
import routes from "../../../infrastructure/navigation/routes";

import Spacer from "../../../components/utils/spacer.component";

import Photo from "../components/photo-set-photos/photo.component";

import GeneratedPhotoModal from "../../photo-upload/components/generated-photos/generated-photo-modal.component";

function PhotoSetPhotosScreen() {
  const { id } = useParams();
  const { state } = useLocation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    reduxStoreBusinessPhotos,
    reduxStoreHikingPhotos,
    reduxStoreRockstarPhotos,
    reduxStoreHoldingPuppyPhotos,
    getAIPhotosByUUIDObj,
  } = useAppSelector(photoSelector);
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string>("");

  useEffect(() => {
    if (state === null && id) {
      dispatch(setReduxStoreBusinessPhotos([]));
      dispatch(setReduxStoreHikingPhotos([]));
      dispatch(setReduxStoreHoldingPuppyPhotos([]));
      dispatch(setReduxStoreRockstarPhotos([]));
      dispatch(getAIPhotosByUUID({ uuid: id })).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          if (payload.data.status === "success") {
            if (payload.data.categorised_remote_images[0].photos.length > 0) {
              dispatch(
                setReduxStoreBusinessPhotos(
                  payload.data.categorised_remote_images[0].photos
                )
              );
            }

            if (payload.data.categorised_remote_images[1].photos.length > 0) {
              dispatch(
                setReduxStoreHikingPhotos(
                  payload.data.categorised_remote_images[1].photos
                )
              );
            }

            if (payload.data.categorised_remote_images[2].photos.length > 0) {
              dispatch(
                setReduxStoreRockstarPhotos(
                  payload.data.categorised_remote_images[2].photos
                )
              );
            }

            if (payload.data.categorised_remote_images[3].photos.length > 0) {
              dispatch(
                setReduxStoreHoldingPuppyPhotos(
                  payload.data.categorised_remote_images[3].photos
                )
              );
            }
          }
        }
      });
    } else {
      dispatch(
        setReduxStoreBusinessPhotos(
          state.item.categorised_remote_images[0].photos
        )
      );
      dispatch(
        setReduxStoreHikingPhotos(
          state.item.categorised_remote_images[1].photos
        )
      );
      dispatch(
        setReduxStoreHoldingPuppyPhotos(
          state.item.categorised_remote_images[2].photos
        )
      );
      dispatch(
        setReduxStoreRockstarPhotos(
          state.item.categorised_remote_images[3].photos
        )
      );
    }
  }, [id, state, dispatch]);

  return (
    <>
      <HeaderContent handleBack={() => navigate(routes.PHOTO_SETS)} />
      <Spacer position="top" size="xl" />
      <Grid container justifyContent="center">
        <Grid item xs={12} sx={{ margin: "0px 24px" }}>
          <Grid
            container
            sx={{
              borderRadius: "50px",
              borderTop: "4px solid #E8E8E8",
              borderRight: "4px solid #E8E8E8",
              borderLeft: "4px solid #E8E8E8",
              background: "#F8F3FA",
              minHeight: "calc(100vh - 80px)",
              padding: "16px",
            }}
          >
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  sx={{
                    marginTop: belowBreakPoint ? "24px" : "60px",
                    marginBottom: "36px",
                  }}
                >
                  {state && (
                    <Typography variant="banner">
                      Set #{state.item.id}
                    </Typography>
                  )}
                  {state === null &&
                    getAIPhotosByUUIDObj.status === "succeeded" && (
                      <Typography variant="banner">
                        Set #{getAIPhotosByUUIDObj.data.id}
                      </Typography>
                    )}
                </Grid>
                {getAIPhotosByUUIDObj.status === "pending" && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    sx={{ marginTop: "calc(100vh/2 - 250px)" }}
                  >
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#b8b8b8"
                      ariaLabel="three-dots-loading"
                      visible={true}
                    />
                  </Grid>
                )}
                {reduxStoreBusinessPhotos.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreBusinessPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                BUSINESS
                              </Typography>
                            )}
                            <Photo
                              item={photo}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}
                {reduxStoreHikingPhotos.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreHikingPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                HIKING
                              </Typography>
                            )}
                            <Photo
                              item={photo}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}
                {reduxStoreRockstarPhotos.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreRockstarPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                ROCKSTAR
                              </Typography>
                            )}
                            <Photo
                              item={photo}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}
                {reduxStoreHoldingPuppyPhotos.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ marginBottom: "36px" }}
                      >
                        {reduxStoreHoldingPuppyPhotos.map((photo, index) => (
                          <Grid
                            item
                            key={`${photo.id}-${photo.remote_url}`}
                            sx={{
                              position: "relative",
                              margin: belowBreakPoint ? "2px" : "12px",
                              width: belowBreakPoint ? "146px" : "233px",
                              height: belowBreakPoint ? "210.142px" : "292px",
                            }}
                          >
                            {index === 0 && (
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(4, 2, 5, 0.30)",
                                  position: "absolute",
                                  top: "-36px",
                                  left: 0,
                                }}
                              >
                                HOLDING PUPPY
                              </Typography>
                            )}
                            <Photo
                              item={photo}
                              setOpenModal={setOpenModal}
                              setSelectedPhoto={setSelectedPhoto}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}
                {reduxStoreBusinessPhotos.length > 0 &&
                  reduxStoreHikingPhotos.length > 0 &&
                  reduxStoreRockstarPhotos.length > 0 &&
                  reduxStoreHoldingPuppyPhotos.length > 0 && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        sx={{ marginBottom: "24px" }}
                      ></Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Spacer position="top" size="xxl" />
      <GeneratedPhotoModal
        remote_url={selectedPhoto}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default PhotoSetPhotosScreen;
