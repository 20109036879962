import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getCredit } from "../../../store/credit/credit-slice.service";
import { authSelector, setUser } from "../../../store/auth/auth-slice.service";
import {
  cancelOrder,
  getOrder,
  orderSelector,
} from "../../../store/order/order-slice.service";
import { uploadPhotos } from "../../../store/photo/photo-slice.service";

import HeaderContent from "../../../infrastructure/layout/components/header-content.component";

import routes from "../../../infrastructure/navigation/routes";

import CircularCrossUnpaidIcon from "../../../fixtures/icons/circular-cross-unpaid-icon";
import CircularCheckPaidIcon from "../../../fixtures/icons/circular-check-paid-icon";

import ContentContainer from "../../../components/utils/content-container.component";
import CtaButton from "../../../components/utils/cta-button.component";

function OrderScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { getOrderObj, cancelOrderObj } = useAppSelector(orderSelector);
  const [ctaProceedingText, setCtaProceedingText] =
    useState<string>("Start generating");
  const [disabledCtaProceedingBtn, setDisabledCtaProceedingBtn] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const uploadPreviousValidatedPhotos = async () => {
    const failedIndexes = localStorage.getItem("failed-indexes") as string;
    const recordedIndexes = localStorage.getItem("indexes") as string;
    const photos: string[] = [];
    if (
      recordedIndexes &&
      recordedIndexes.split(",").length === 7 &&
      !failedIndexes
    ) {
      const indexes: number[] = recordedIndexes
        .split(",")
        .map((index) => parseInt(index))
        .sort();
      indexes.forEach((index) => {
        const storage = localStorage.getItem(`photo-${index}`) as string;
        photos.push(JSON.parse(storage).remote_url);
      });
      dispatch(uploadPhotos({ photos })).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          dispatch(getCredit());
          Cookies.set("remote_uuid", payload.data.remote_uuid);
          navigate(routes.PHOTO_POST_UPLOADED);
        }
      });
    }
  };

  const onLoad = () => {
    const order_id = searchParams.get("orderId") as string;
    const failedIndexes = localStorage.getItem("failed-indexes") as string;
    const recordedIndexes = localStorage.getItem("indexes") as string;
    if (
      recordedIndexes &&
      recordedIndexes.split(",").length === 7 &&
      !failedIndexes
    ) {
      setDisabledCtaProceedingBtn(true);
      setCtaProceedingText("Generating photos . . .");
    }
    dispatch(getOrder({ order_id })).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        if (payload.data.status === "paid") {
          dispatch(getCredit()).then(({ meta, payload }) => {
            if (meta.requestStatus === "fulfilled") {
              dispatch(
                setUser({
                  ...user,
                  credit_balance: payload.data.credit_balance,
                })
              );
            }
          });
          uploadPreviousValidatedPhotos();
        }
      }
    });
  };

  const handleProceeding = () => {
    if (
      getOrderObj.status === "succeeded" &&
      (getOrderObj.data.status === "payment_failed" ||
        getOrderObj.data.status === "pending_payment")
    ) {
      window.open(getOrderObj.data.pay_url, "_self");
    } else {
      navigate(routes.PHOTO_UPLOAD);
    }
  };

  const handleCancelOrder = () => {
    const order_id = searchParams.get("orderId") as string;
    dispatch(cancelOrder({ order_id })).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.PHOTO_UPLOAD);
      }
    });
  };

  useEffect(() => {
    onLoad();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentContainer>
        <HeaderContent hideBackBtn={true} disabledCreditBtn={true} />
        <Grid
          container
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            component="div"
            sx={{
              position: "fixed",
              top: "calc(100vh /1.8)",
              left: 0,
              height: "380px",
              filter: "blur(300.81px)",
              width: "100vw",
              transform: "rotate(23.75deg)",
              background:
                "linear-gradient(180deg, rgba(237, 85, 158, 0.20) 0%, rgba(138, 91, 238, 0.20) 100%)",
            }}
          />

          {getOrderObj.status === "pending" && (
            <>
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress color="secondary" size={80} />
              </Grid>
            </>
          )}
          {getOrderObj.status === "succeeded" &&
            getOrderObj.data.status === "paid" && (
              <>
                <Box
                  component="div"
                  sx={{
                    position: "fixed",
                    top: "calc(100vh /1.8)",
                    left: 0,
                    height: "380px",
                    filter: "blur(100.81px)",
                    width: "100vw",
                    transform: "rotate(23.75deg)",
                    background:
                      "linear-gradient(180deg, rgba(237, 85, 158, 0.20) 0%, rgba(138, 91, 238, 0.20) 100%)",
                  }}
                />
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <CircularCheckPaidIcon />
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item xs={10} md={12}>
                        <Grid
                          item
                          container
                          sx={{ marginTop: "16px" }}
                          justifyContent="center"
                        >
                          <Typography variant="h3" sx={{ textAlign: "center" }}>
                            Thank you for your purchase
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item xs={10} md={12}>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          sx={{ marginTop: "16px" }}
                        >
                          <Typography variant="h5" sx={{ textAlign: "center" }}>
                            You've bought 1 set. Now you're able to use Dapper
                            AI
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        sx={{ marginTop: "32px" }}
                      >
                        <Grid item xs={8} md={4}>
                          <Grid
                            container
                            sx={{
                              backgroundColor: "#FFFFFF03",
                              border: "2px solid #d663ffa6",
                              borderRadius: "25px",
                              height: "80px",
                              position: "relative",
                            }}
                          >
                            <Grid
                              item
                              container
                              justifyContent="center"
                              sx={{ paddingTop: "8px" }}
                            >
                              <Typography variant="price">$2.99</Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="center"
                              sx={{ marginTop: "-16px" }}
                            >
                              <Typography variant="h5">1 set</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent="center"
                      sx={{ marginTop: "32px", marginBottom: "30px" }}
                    >
                      <Grid item xs={8} md={4}>
                        <CtaButton
                          text={ctaProceedingText}
                          disabled={disabledCtaProceedingBtn}
                          onClick={handleProceeding}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

          {getOrderObj.status === "succeeded" &&
            (getOrderObj.data.status === "payment_failed" ||
              getOrderObj.data.status === "pending_payment") && (
              <>
                <Box
                  component="div"
                  sx={{
                    position: "fixed",
                    top: "calc(100vh /1.8)",
                    left: 0,
                    height: "380px",
                    filter: "blur(100.81px)",
                    width: "100vw",
                    transform: "rotate(23.75deg)",
                    background:
                      "linear-gradient(180deg, rgba(237, 85, 158, 0.20) 0%, rgba(138, 91, 238, 0.20) 100%)",
                  }}
                />
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <CircularCrossUnpaidIcon />
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item xs={10} md={12}>
                        <Grid
                          item
                          container
                          sx={{ marginTop: "32px" }}
                          justifyContent="center"
                        >
                          <Typography variant="h3" sx={{ textAlign: "center" }}>
                            Purchased Failed
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item xs={10} md={12}>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          sx={{ marginTop: "24px" }}
                        >
                          <Typography variant="h5" sx={{ textAlign: "center" }}>
                            Something went wrong. Please try again
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      sx={{ marginTop: "32px", marginBottom: "12px" }}
                    >
                      <Grid item xs={8} md={4}>
                        <CtaButton
                          text="Try again"
                          onClick={handleProceeding}
                          disabled={cancelOrderObj.status === "pending"}
                          background="#F92F6C"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent="center"
                      sx={{ marginTop: "12px", marginBottom: "30px" }}
                    >
                      <Grid item xs={8} md={4}>
                        <CtaButton
                          text="Cancel order"
                          textColour="#040205"
                          onClick={handleCancelOrder}
                          disabled={cancelOrderObj.status === "pending"}
                          loading={cancelOrderObj.status === "pending"}
                          background="#FFF"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
        </Grid>
      </ContentContainer>
    </>
  );
}

export default OrderScreen;
