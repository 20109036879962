import { useNavigate, useLocation } from "react-router-dom";

import routes from "../../navigation/routes";
import HeaderContent from "./header-content.component";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    if (
      location.pathname === routes.PHOTO_POST_UPLOADED ||
      location.pathname === routes.GENERATED_PHOTOS
    ) {
      navigate(routes.PHOTO_UPLOAD);
    } else {
      navigate(-1);
    }
  };

  return <HeaderContent handleBack={handleBack} />;
}

export default Header;
