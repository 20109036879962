import { Box } from "@mui/material";

function DeleteIcon() {
  return (
    <>
      <Box
        sx={{
          height: "25px",
          width: "25px",
          background:
            "radial-gradient(50.46% 66.91% at 49.77% 100.00%, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0.00) 100%), #C957F1",
          boxShadow: "0px 4px 54px 0px rgba(255, 255, 255, 0.25)",
          borderRadius: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            height: "3px",
            width: "13px",
            background: "#FFF",
            borderRadius: "1px",
          }}
        />
      </Box>
    </>
  );
}

export default DeleteIcon;
