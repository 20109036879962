import {
  Grid,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import CheckIcon from "../../../fixtures/icons/check.icon";
import CircularCrossIcon from "../../../fixtures/icons/circular-cross-icon";

import CtaButton from "../../../components/utils/cta-button.component";
import routes from "../../../infrastructure/navigation/routes";

function OrderProductScreen() {
  const navigate = useNavigate();
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          position: "relative",
        }}
      >
        <Box
          component="div"
          sx={{
            position: "fixed",
            top: "calc(100vh /1.8)",
            left: 0,
            height: "380px",
            filter: "blur(100.81px)",
            width: "100vw",
            transform: "rotate(23.75deg)",
            background:
              "linear-gradient(180deg, rgba(237, 85, 158, 0.20) 0%, rgba(138, 91, 238, 0.20) 100%)",
          }}
        />
        <Grid
          item
          xs={12}
          lg={6}
          order={{ xs: 2, lg: 1 }}
          sx={{
            placeSelf: "center",
            zIndex: 1,
            marginTop: { xs: "-180px", md: "-300px", lg: "30px" },
          }}
        >
          <Grid container>
            <Grid item container justifyContent="center">
              <Box
                component="img"
                sx={{ width: "220px", height: "auto" }}
                alt="The house from the offer."
                src="images/order/dapper-ai-logo-corgi.png"
              />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              sx={{ marginTop: "12px" }}
            >
              <Grid
                item
                container
                xs={10}
                lg={8}
                sx={{
                  borderRadius: "25px",
                  padding: "24px",
                  backdropFilter: "blur(75px) brightness(100%)",
                  backgroundColor: "#ffffff03",
                  boxShadow: "0px 0px 65.75px #d661ff40",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={1}>
                        <Box
                          sx={{
                            backgroundColor: "#FFF",
                            borderRadius: "25px",
                            width: "25px",
                            height: "25px",
                            paddingTop: "2px",
                            paddingLeft: "2px",
                          }}
                        >
                          <CheckIcon color="#C957F1" />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        sx={{ paddingLeft: { xs: "16px", lg: "8px" } }}
                      >
                        <Typography variant="h5">
                          Get more attention on LinkedIn and dating apps
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "16px" }}>
                    <Grid container>
                      <Grid item xs={1}>
                        <Box
                          sx={{
                            backgroundColor: "#FFF",
                            borderRadius: "25px",
                            width: "25px",
                            height: "25px",
                            paddingTop: "2px",
                            paddingLeft: "2px",
                          }}
                        >
                          <CheckIcon color="#C957F1" />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        sx={{ paddingLeft: { xs: "16px", lg: "8px" } }}
                      >
                        <Typography variant="h5">
                          Look your best & improve your career or dating life
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "16px" }}>
                    <Grid container>
                      <Grid item xs={1}>
                        <Box
                          sx={{
                            backgroundColor: "#FFF",
                            borderRadius: "25px",
                            width: "25px",
                            height: "25px",
                            paddingTop: "2px",
                            paddingLeft: "2px",
                          }}
                        >
                          <CheckIcon color="#C957F1" />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        sx={{ paddingLeft: { xs: "16px", lg: "8px" } }}
                      >
                        <Typography variant="h5">Upgrade your style</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              sx={{ marginTop: "12px" }}
            >
              <Grid item xs={10} lg={8}>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#FFFFFF03",
                    border: "2px solid rgba(214, 100, 255, 0.15);",
                    borderRadius: "25px",
                    height: "70px",
                    position: "relative",
                  }}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    sx={{ paddingTop: "4px" }}
                  >
                    <Typography variant="price">$2.99</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    sx={{ marginTop: "-8px" }}
                  >
                    <Typography variant="h5">1 set</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Grid item xs={10} lg={8}>
                <CtaButton
                  text="Continue"
                  onClick={() => navigate(routes.ORDER_PRODUCT_CONFIRMATION)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
          {belowBreakPoint ? (
            <>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
                alt="Rectangle"
                src="images/order/collage-photos-mobile.png"
              />
            </>
          ) : (
            <>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
                alt="Rectangle"
                src="images/order/collage-photos-desktop.png"
              />
            </>
          )}
          <Box sx={{ position: "absolute", top: 10, right: 15 }}>
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                navigate(routes.PHOTO_UPLOAD);
              }}
            >
              <CircularCrossIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default OrderProductScreen;
