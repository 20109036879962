import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROUTES}`,
  timeout: 150000,
  headers: {
    Accept: "application/json",
  },
});

api.interceptors.request.use((request) => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line
    console.log(
      `-> AXIOS LOG: Starting Request ${request.method} - ${request.url}`,
      request
    );
  }
  return request;
});

api.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.log(
        `<- AXIOS LOG: Response: ${response.config.method} - ${response.config.url}`,
        response
      );
    }
    return response;
  },
  (error) => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.warn("AXIOS LOG: Error:", error.response);
    }
    return Promise.reject(error.response.data);
  }
);

export async function getReq(url: string) {
  const response = await api
    .get(url, {
      headers: {
        Authorization: Cookies.get("auth_token"),
      },
    })
    .then((res) => res.data);
  return response;
}

export async function postReq(url: string, payload: any) {
  const response = await api
    .post(url, payload, {
      headers: {
        Authorization: Cookies.get("auth_token"),
      },
    })
    .then((res) => res.data);
  return response;
}

export async function putReq(url: string, payload: any) {
  const response = await api
    .put(url, payload, {
      headers: {
        Authorization: Cookies.get("auth_token"),
      },
    })
    .then((res) => res.data);
  return response;
}

export async function delReq(url: string, payload: any) {
  const response = await api
    .delete(url, {
      headers: { Authorization: Cookies.get("auth_token") },
      data: payload,
    })
    .then((res) => res.data);
  return response;
}

export async function postReqMultiPartFormData(url: string, payload: any) {
  const response = await api
    .post(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Cookies.get("auth_token"),
      },
    })
    .then((res) => res.data);
  return response;
}
