function AppleDownloadIcon() {
  return (
    <>
      <svg width="204" height="68" viewBox="0 0 204 68" fill="none">
        <g clipPath="url(#clip0_2528_1450)">
          <path
            d="M187.755 0.000221001H16.2541C15.6289 0.000221001 15.0113 0.000221001 14.3878 0.00362102C13.8659 0.00702103 13.3481 0.0168981 12.8212 0.0252111C11.6764 0.0386443 10.5344 0.139071 9.40496 0.32562C8.27711 0.516229 7.1846 0.875582 6.16437 1.39152C5.14539 1.91183 4.21432 2.58792 3.40493 3.39526C2.59129 4.20032 1.91307 5.13087 1.39632 6.15112C0.878156 7.16934 0.518857 8.26046 0.330836 9.38678C0.141027 10.5117 0.0388894 11.6496 0.0253391 12.7902C0.00951878 13.3114 0.0078481 13.8344 -0.000488281 14.3557V53.6501C0.0078481 54.178 0.00951878 54.6893 0.0253391 55.2173C0.0388935 56.3579 0.141031 57.4957 0.330836 58.6206C0.518338 59.7476 0.877658 60.8393 1.39632 61.8579C1.91283 62.8749 2.59115 63.8016 3.40493 64.6022C4.21125 65.4132 5.14292 66.0897 6.16437 66.606C7.1846 67.1234 8.27699 67.4849 9.40496 67.6784C10.5346 67.8635 11.6765 67.9639 12.8212 67.979C13.3481 67.9905 13.8659 67.9972 14.3878 67.9972C15.0112 68.0006 15.6289 68.0005 16.2541 68.0005H187.755C188.368 68.0005 188.99 68.0006 189.603 67.9972C190.123 67.9972 190.655 67.9905 191.175 67.979C192.317 67.9647 193.457 67.8643 194.584 67.6784C195.716 67.4835 196.812 67.1221 197.837 66.606C198.858 66.0894 199.789 65.4129 200.594 64.6022C201.406 63.7985 202.086 62.8724 202.609 61.8579C203.124 60.8386 203.479 59.7469 203.664 58.6206C203.854 57.4956 203.96 56.358 203.981 55.2173C203.987 54.6893 203.987 54.178 203.987 53.6501C204.001 53.0325 204.001 52.4183 204.001 51.7906V16.2117C204.001 15.5892 204.001 14.9716 203.987 14.3557C203.987 13.8344 203.987 13.3114 203.981 12.7901C203.96 11.6494 203.854 10.5118 203.664 9.38671C203.479 8.26104 203.123 7.17001 202.609 6.15105C201.556 4.10607 199.888 2.44148 197.837 1.39137C196.812 0.87669 195.716 0.517432 194.584 0.325467C193.457 0.138096 192.317 0.0376344 191.175 0.0249731C190.655 0.0166771 190.123 0.00671503 189.603 0.00340002C188.99 0 188.368 0 187.755 0V0.000221001Z"
            fill="black"
          />
          <path
            d="M14.3963 66.5131C13.8769 66.5131 13.3701 66.5065 12.8547 66.4949C11.7871 66.4811 10.7221 66.3884 9.66824 66.2177C8.68559 66.0489 7.73366 65.735 6.84386 65.2862C5.96222 64.8412 5.15809 64.2577 4.46228 63.558C3.7564 62.8666 3.16872 62.0646 2.72254 61.1839C2.27143 60.2975 1.95924 59.3474 1.79684 58.3667C1.62146 57.3129 1.52657 56.2473 1.513 55.1792C1.50219 54.8206 1.48804 53.6269 1.48804 53.6269V14.3557C1.48804 14.3557 1.50311 13.1803 1.51308 12.835C1.52608 11.7686 1.62042 10.7046 1.79527 9.65247C1.95797 8.66905 2.27041 7.7162 2.72175 6.82689C3.1663 5.94682 3.75071 5.14427 4.45234 4.45036C5.15318 3.74986 5.95988 3.16333 6.84303 2.71216C7.73078 2.26486 8.68091 1.95314 9.66157 1.78745C10.7189 1.61502 11.7876 1.52179 12.8589 1.50855L14.3972 1.48779H189.595L191.152 1.50938C192.213 1.52196 193.272 1.61436 194.32 1.78579C195.31 1.95355 196.27 2.26744 197.168 2.71714C198.938 3.62638 200.377 5.06489 201.285 6.83104C201.729 7.71421 202.037 8.65929 202.198 9.63422C202.375 10.695 202.474 11.7673 202.494 12.8425C202.499 13.3239 202.499 13.8411 202.499 14.3557C202.512 14.9932 202.512 15.6 202.512 16.2118V51.7907C202.512 52.4083 202.512 53.011 202.499 53.6186C202.499 54.1714 202.499 54.6778 202.492 55.1991C202.472 56.2551 202.375 57.3082 202.201 58.35C202.042 59.3378 201.731 60.2954 201.28 61.189C200.831 62.0601 200.247 62.8551 199.549 63.5447C198.853 64.2482 198.047 64.8351 197.163 65.283C196.268 65.7352 195.309 66.0503 194.32 66.2177C193.266 66.3893 192.201 66.482 191.133 66.4949C190.634 66.5065 190.111 66.5131 189.603 66.5131L187.755 66.5165L14.3963 66.5131Z"
            fill="white"
          />
          <path
            d="M42.2257 34.5095C42.244 33.0906 42.622 31.6994 43.3244 30.4652C44.0268 29.2311 45.0309 28.1942 46.2431 27.451C45.473 26.3542 44.457 25.4516 43.2759 24.8149C42.0948 24.1781 40.7811 23.8248 39.4391 23.7829C36.5763 23.4833 33.801 25.4911 32.3422 25.4911C30.8552 25.4911 28.6091 23.8127 26.1905 23.8623C24.6261 23.9127 23.1014 24.3664 21.7651 25.1791C20.4287 25.9918 19.3263 27.1358 18.5652 28.4997C15.2681 34.192 17.7274 42.5579 20.8857 47.1591C22.4659 49.4122 24.3126 51.929 26.7291 51.8398C29.0937 51.742 29.9769 50.3361 32.8312 50.3361C35.659 50.3361 36.4876 51.8398 38.953 51.783C41.4904 51.7419 43.0891 49.5199 44.6138 47.2455C45.7491 45.64 46.6228 43.8657 47.2024 41.9882C45.7282 41.3664 44.4702 40.3257 43.5852 38.9958C42.7002 37.6659 42.2274 36.1056 42.2257 34.5095Z"
            fill="black"
          />
          <path
            d="M37.5698 20.7573C38.9533 19.1012 39.6349 16.9724 39.4698 14.8232C37.3562 15.0446 35.4038 16.052 34.0016 17.6446C33.3161 18.4226 32.791 19.3278 32.4564 20.3083C32.1219 21.2888 31.9844 22.3255 32.0518 23.359C33.109 23.3698 34.1549 23.1413 35.1107 22.6907C36.0665 22.24 36.9073 21.579 37.5698 20.7573Z"
            fill="black"
          />
          <path
            d="M71.961 46.1366H63.8916L61.9538 51.8426H58.5359L66.1791 30.7319H69.7302L77.3734 51.8426H73.8972L71.961 46.1366ZM64.7274 43.5036H71.1237L67.9705 34.2432H67.8823L64.7274 43.5036Z"
            fill="black"
          />
          <path
            d="M93.8795 44.1475C93.8795 48.9305 91.3124 52.0034 87.4383 52.0034C86.457 52.0546 85.481 51.8292 84.6221 51.3529C83.7633 50.8767 83.0563 50.1689 82.582 49.3106H82.5087V56.9341H79.3406V36.451H82.4072V39.011H82.4655C82.9615 38.1568 83.6805 37.4526 84.5459 36.9735C85.4113 36.4944 86.3907 36.2581 87.3801 36.2899C91.2973 36.29 93.8795 39.3779 93.8795 44.1475ZM90.6231 44.1475C90.6231 41.0314 89.0083 38.9827 86.5443 38.9827C84.1237 38.9827 82.4955 41.0745 82.4955 44.1475C82.4955 47.2487 84.1237 49.3256 86.5443 49.3256C89.0083 49.3256 90.6231 47.2919 90.6231 44.1475Z"
            fill="black"
          />
          <path
            d="M110.869 44.1475C110.869 48.9304 108.301 52.0034 104.427 52.0034C103.446 52.0546 102.47 51.8292 101.611 51.3529C100.752 50.8767 100.045 50.1689 99.5711 49.3106H99.4977V56.9341H96.3296V36.451H99.3962V39.0109H99.4544C99.9505 38.1568 100.669 37.4526 101.535 36.9735C102.4 36.4944 103.38 36.2581 104.369 36.2899C108.286 36.2899 110.869 39.3778 110.869 44.1475ZM107.612 44.1475C107.612 41.0314 105.997 38.9827 103.533 38.9827C101.113 38.9827 99.4845 41.0745 99.4845 44.1475C99.4845 47.2487 101.113 49.3256 103.533 49.3256C105.997 49.3256 107.612 47.2919 107.612 44.1475H107.612Z"
            fill="black"
          />
          <path
            d="M122.096 45.9606C122.33 48.0541 124.37 49.4286 127.157 49.4286C129.827 49.4286 131.748 48.054 131.748 46.1664C131.748 44.5278 130.59 43.5467 127.846 42.8743L125.102 42.2152C121.215 41.2789 119.41 39.466 119.41 36.5242C119.41 32.8818 122.593 30.3799 127.113 30.3799C131.587 30.3799 134.653 32.8818 134.757 36.5242H131.559C131.367 34.4174 129.621 33.1457 127.068 33.1457C124.516 33.1457 122.77 34.4324 122.77 36.305C122.77 37.7975 123.885 38.6757 126.614 39.348L128.946 39.9191C133.29 40.9435 135.095 42.6834 135.095 45.7712C135.095 49.7207 131.94 52.1944 126.922 52.1944C122.227 52.1944 119.057 49.7788 118.853 45.9605L122.096 45.9606Z"
            fill="black"
          />
          <path
            d="M141.933 32.8096V36.452H144.868V38.9538H141.933V47.4389C141.933 48.7571 142.521 49.3714 143.811 49.3714C144.16 49.3653 144.507 49.3409 144.853 49.2983V51.7852C144.273 51.8933 143.684 51.9422 143.094 51.9313C139.969 51.9313 138.75 50.7609 138.75 47.7759V38.9538H136.506V36.452H138.75V32.8096H141.933Z"
            fill="black"
          />
          <path
            d="M146.566 44.1475C146.566 39.3048 149.426 36.2617 153.886 36.2617C158.361 36.2617 161.208 39.3047 161.208 44.1475C161.208 49.0035 158.376 52.0333 153.886 52.0333C149.398 52.0333 146.566 49.0035 146.566 44.1475ZM157.98 44.1475C157.98 40.8255 156.454 38.8649 153.886 38.8649C151.319 38.8649 149.794 40.8405 149.794 44.1475C149.794 47.4828 151.319 49.4285 153.886 49.4285C156.454 49.4285 157.98 47.4828 157.98 44.1475H157.98Z"
            fill="black"
          />
          <path
            d="M163.821 36.4528H166.842V39.0725H166.916C167.12 38.2543 167.601 37.5313 168.277 37.0248C168.953 36.5184 169.783 36.2596 170.628 36.2917C170.993 36.2905 171.358 36.33 171.714 36.4096V39.3647C171.253 39.2243 170.772 39.1598 170.29 39.1738C169.83 39.1552 169.371 39.2361 168.945 39.411C168.519 39.5859 168.136 39.8507 167.823 40.1871C167.509 40.5235 167.272 40.9236 167.128 41.3599C166.984 41.7963 166.937 42.2585 166.989 42.7149V51.8442H163.821L163.821 36.4528Z"
            fill="black"
          />
          <path
            d="M186.321 47.3217C185.895 50.1158 183.167 52.0333 179.675 52.0333C175.185 52.0333 172.398 49.0334 172.398 44.2205C172.398 39.3928 175.2 36.2617 179.542 36.2617C183.812 36.2617 186.498 39.1869 186.498 43.8537V44.9361H175.597V45.127C175.546 45.6934 175.617 46.2641 175.805 46.801C175.993 47.3379 176.293 47.8289 176.686 48.2412C177.079 48.6535 177.555 48.9778 178.083 49.1926C178.611 49.4073 179.179 49.5075 179.749 49.4866C180.497 49.5565 181.249 49.3836 181.891 48.9936C182.533 48.6035 183.032 48.0172 183.313 47.3217L186.321 47.3217ZM175.612 42.7281H183.328C183.356 42.2187 183.279 41.7091 183.101 41.231C182.922 40.7529 182.646 40.3168 182.291 39.95C181.936 39.5832 181.508 39.2936 181.035 39.0995C180.562 38.9053 180.054 38.8107 179.542 38.8217C179.026 38.8186 178.515 38.9175 178.037 39.1125C177.56 39.3076 177.126 39.595 176.761 39.9582C176.395 40.3214 176.106 40.7531 175.908 41.2285C175.711 41.7039 175.61 42.2136 175.612 42.7281V42.7281Z"
            fill="black"
          />
          <path
            d="M64.4855 14.844C65.1498 14.7965 65.8163 14.8966 66.437 15.137C67.0577 15.3774 67.6172 15.7523 68.0751 16.2344C68.533 16.7166 68.8778 17.2941 69.0848 17.9252C69.2918 18.5564 69.3557 19.2255 69.2719 19.8843C69.2719 23.1249 67.5155 24.9877 64.4855 24.9877H60.8113V14.844H64.4855ZM62.3912 23.5532H64.3091C64.7837 23.5815 65.2587 23.5039 65.6994 23.326C66.1402 23.1482 66.5356 22.8746 66.8569 22.5251C67.1783 22.1756 67.4174 21.7591 67.557 21.3059C67.6966 20.8526 67.733 20.374 67.6637 19.905C67.728 19.4377 67.6878 18.9621 67.5461 18.5122C67.4044 18.0623 67.1647 17.6492 66.8441 17.3025C66.5235 16.9558 66.1299 16.6841 65.6917 16.5069C65.2534 16.3297 64.7813 16.2514 64.3091 16.2775H62.3912V23.5532Z"
            fill="black"
          />
          <path
            d="M71.055 21.1564C71.0067 20.6534 71.0644 20.1458 71.2244 19.6663C71.3844 19.1868 71.6431 18.7459 71.9839 18.3719C72.3248 17.9979 72.7403 17.6991 73.2037 17.4947C73.6672 17.2902 74.1684 17.1846 74.6751 17.1846C75.1819 17.1846 75.6831 17.2902 76.1465 17.4947C76.61 17.6991 77.0255 17.9979 77.3663 18.3719C77.7072 18.7459 77.9659 19.1868 78.1259 19.6663C78.2859 20.1458 78.3436 20.6534 78.2953 21.1564C78.3445 21.66 78.2875 22.1683 78.1279 22.6486C77.9683 23.1289 77.7098 23.5706 77.3688 23.9453C77.0279 24.32 76.6121 24.6194 76.1482 24.8243C75.6843 25.0292 75.1825 25.1351 74.6751 25.1351C74.1678 25.1351 73.666 25.0292 73.2021 24.8243C72.7382 24.6194 72.3224 24.32 71.9814 23.9453C71.6405 23.5706 71.3819 23.1289 71.2224 22.6486C71.0628 22.1683 71.0058 21.66 71.055 21.1564ZM76.737 21.1564C76.737 19.4971 75.9895 18.5267 74.6776 18.5267C73.3608 18.5267 72.62 19.4971 72.62 21.1565C72.62 22.8291 73.3608 23.792 74.6776 23.792C75.9895 23.7919 76.737 22.8224 76.737 21.1564H76.737Z"
            fill="black"
          />
          <path
            d="M87.9219 24.9875H86.3503L84.7638 19.3496H84.6439L83.064 24.9875H81.5073L79.3914 17.3325H80.928L82.3031 23.1737H82.4164L83.9946 17.3325H85.448L87.0262 23.1737H87.1461L88.5146 17.3325H90.0296L87.9219 24.9875Z"
            fill="black"
          />
          <path
            d="M91.8093 17.3321H93.2677V18.5481H93.3809C93.573 18.1114 93.8969 17.7452 94.3075 17.5008C94.7182 17.2563 95.1951 17.1457 95.6717 17.1843C96.0452 17.1563 96.4203 17.2124 96.7691 17.3486C97.1179 17.4848 97.4315 17.6975 97.6867 17.9709C97.9418 18.2443 98.1321 18.5715 98.2432 18.9282C98.3544 19.2849 98.3836 19.662 98.3288 20.0315V24.987H96.8138V20.4108C96.8138 19.1807 96.2777 18.5689 95.1573 18.5689C94.9037 18.5571 94.6505 18.6001 94.4152 18.6951C94.1798 18.79 93.9678 18.9345 93.7937 19.1188C93.6196 19.303 93.4875 19.5226 93.4065 19.7626C93.3255 20.0025 93.2974 20.257 93.3243 20.5088V24.9871H91.8093L91.8093 17.3321Z"
            fill="black"
          />
          <path
            d="M100.744 14.3438H102.259V24.9871H100.744V14.3438Z"
            fill="black"
          />
          <path
            d="M104.364 21.1566C104.315 20.6535 104.373 20.1459 104.533 19.6663C104.693 19.1868 104.952 18.7459 105.293 18.3719C105.634 17.9979 106.049 17.6991 106.513 17.4947C106.976 17.2902 107.477 17.1846 107.984 17.1846C108.491 17.1846 108.992 17.2902 109.456 17.4947C109.919 17.6991 110.335 17.9979 110.675 18.3719C111.016 18.7459 111.275 19.1868 111.435 19.6663C111.595 20.1459 111.653 20.6535 111.605 21.1566C111.654 21.6602 111.597 22.1685 111.437 22.6487C111.278 23.129 111.019 23.5707 110.678 23.9454C110.337 24.3201 109.921 24.6195 109.457 24.8244C108.993 25.0293 108.492 25.1352 107.984 25.1352C107.477 25.1352 106.975 25.0293 106.511 24.8244C106.047 24.6195 105.631 24.3201 105.29 23.9454C104.949 23.5707 104.691 23.129 104.531 22.6487C104.371 22.1685 104.314 21.6602 104.364 21.1566ZM110.046 21.1566C110.046 19.4972 109.298 18.5269 107.986 18.5269C106.669 18.5269 105.929 19.4972 105.929 21.1566C105.929 22.8292 106.669 23.7921 107.986 23.7921C109.298 23.7921 110.046 22.8225 110.046 21.1566H110.046Z"
            fill="black"
          />
          <path
            d="M113.2 22.8225C113.2 21.4445 114.229 20.6502 116.056 20.5373L118.135 20.4177V19.757C118.135 18.9485 117.599 18.4919 116.563 18.4919C115.718 18.4919 115.132 18.8016 114.963 19.3428H113.497C113.652 18.0279 114.892 17.1846 116.633 17.1846C118.558 17.1846 119.643 18.14 119.643 19.757V24.9873H118.185V23.9116H118.065C117.822 24.2974 117.48 24.612 117.075 24.8232C116.67 25.0343 116.216 25.1345 115.759 25.1135C115.437 25.1469 115.111 25.1126 114.803 25.0128C114.495 24.913 114.211 24.7499 113.97 24.5341C113.729 24.3183 113.536 24.0544 113.403 23.7596C113.271 23.4648 113.202 23.1456 113.2 22.8225ZM118.135 22.1684V21.5284L116.26 21.6479C115.203 21.7185 114.724 22.0771 114.724 22.7519C114.724 23.4409 115.323 23.8418 116.147 23.8418C116.389 23.8662 116.633 23.8419 116.864 23.7703C117.096 23.6988 117.311 23.5814 117.497 23.4253C117.682 23.2692 117.834 23.0774 117.944 22.8615C118.054 22.6456 118.119 22.4098 118.135 22.1684Z"
            fill="black"
          />
          <path
            d="M121.634 21.1563C121.634 18.7374 122.881 17.2051 124.821 17.2051C125.301 17.183 125.777 17.2976 126.194 17.5354C126.61 17.7733 126.951 18.1245 127.175 18.5481H127.288V14.3438H128.803V24.9871H127.351V23.7776H127.231C126.99 24.1984 126.638 24.5452 126.213 24.7806C125.788 25.0161 125.307 25.1311 124.821 25.1132C122.868 25.1133 121.634 23.5809 121.634 21.1563ZM123.199 21.1563C123.199 22.7799 123.967 23.7569 125.25 23.7569C126.527 23.7569 127.316 22.7658 127.316 21.1629C127.316 19.5675 126.519 18.5623 125.25 18.5623C123.975 18.5623 123.199 19.5459 123.199 21.1563H123.199Z"
            fill="black"
          />
          <path
            d="M135.071 21.1564C135.023 20.6534 135.08 20.1458 135.24 19.6663C135.4 19.1868 135.659 18.7459 136 18.3719C136.341 17.9979 136.756 17.6991 137.22 17.4947C137.683 17.2902 138.184 17.1846 138.691 17.1846C139.198 17.1846 139.699 17.2902 140.162 17.4947C140.626 17.6991 141.041 17.9979 141.382 18.3719C141.723 18.7459 141.982 19.1868 142.142 19.6663C142.302 20.1458 142.359 20.6534 142.311 21.1564C142.36 21.66 142.303 22.1683 142.144 22.6486C141.984 23.1289 141.726 23.5706 141.385 23.9453C141.044 24.32 140.628 24.6194 140.164 24.8243C139.7 25.0292 139.198 25.1351 138.691 25.1351C138.184 25.1351 137.682 25.0292 137.218 24.8243C136.754 24.6194 136.338 24.32 135.997 23.9453C135.656 23.5706 135.398 23.1289 135.238 22.6486C135.079 22.1683 135.022 21.66 135.071 21.1564ZM140.753 21.1564C140.753 19.4971 140.005 18.5267 138.693 18.5267C137.377 18.5267 136.636 19.4971 136.636 21.1565C136.636 22.8291 137.377 23.792 138.693 23.792C140.005 23.7919 140.753 22.8224 140.753 21.1564Z"
            fill="black"
          />
          <path
            d="M144.343 17.3321H145.801V18.5481H145.914C146.106 18.1114 146.43 17.7452 146.841 17.5008C147.251 17.2563 147.728 17.1457 148.205 17.1843C148.578 17.1563 148.953 17.2124 149.302 17.3486C149.651 17.4848 149.965 17.6975 150.22 17.9709C150.475 18.2443 150.665 18.5715 150.776 18.9282C150.888 19.2849 150.917 19.662 150.862 20.0315V24.987H149.347V20.4108C149.347 19.1807 148.811 18.5689 147.69 18.5689C147.437 18.5571 147.184 18.6001 146.948 18.6951C146.713 18.79 146.501 18.9345 146.327 19.1188C146.153 19.303 146.021 19.5226 145.94 19.7626C145.859 20.0025 145.831 20.257 145.858 20.5088V24.9871H144.343V17.3321Z"
            fill="black"
          />
          <path
            d="M159.424 15.4258V17.3665H161.087V18.639H159.424V22.5753C159.424 23.3771 159.755 23.7283 160.509 23.7283C160.702 23.7276 160.895 23.716 161.087 23.6934V24.9518C160.815 25.0003 160.539 25.0261 160.263 25.029C158.578 25.029 157.907 24.438 157.907 22.9621V18.639H156.688V17.3664H157.907V15.4258H159.424Z"
            fill="black"
          />
          <path
            d="M163.155 14.3438H164.656V18.5622H164.776C164.978 18.1214 165.311 17.7532 165.729 17.5078C166.148 17.2624 166.633 17.1518 167.117 17.1909C167.489 17.1708 167.86 17.2326 168.205 17.3721C168.55 17.5116 168.859 17.7252 169.112 17.9978C169.364 18.2704 169.554 18.5952 169.666 18.949C169.778 19.3028 169.81 19.677 169.761 20.0448V24.9871H168.244V20.4175C168.244 19.1947 167.673 18.5755 166.603 18.5755C166.342 18.5542 166.08 18.5899 165.835 18.6801C165.59 18.7703 165.368 18.9128 165.184 19.0976C165 19.2825 164.858 19.5052 164.769 19.7503C164.681 19.9953 164.647 20.2567 164.67 20.5162V24.987H163.155L163.155 14.3438Z"
            fill="black"
          />
          <path
            d="M178.593 22.9206C178.388 23.6202 177.942 24.2254 177.333 24.6302C176.725 25.0351 175.994 25.2138 175.267 25.1352C174.761 25.1485 174.259 25.0519 173.794 24.8521C173.33 24.6523 172.914 24.3541 172.577 23.9782C172.239 23.6022 171.988 23.1576 171.84 22.6751C171.693 22.1926 171.652 21.6838 171.721 21.184C171.654 20.6827 171.695 20.1728 171.843 19.6889C171.99 19.2049 172.24 18.7582 172.576 18.379C172.911 17.9998 173.325 17.6969 173.788 17.4908C174.251 17.2848 174.753 17.1804 175.26 17.1847C177.396 17.1847 178.685 18.6399 178.685 21.0437V21.5709H173.264V21.6555C173.241 21.9365 173.276 22.2192 173.368 22.4857C173.46 22.7522 173.608 22.9965 173.8 23.2029C173.993 23.4094 174.226 23.5735 174.486 23.6847C174.746 23.7959 175.026 23.8517 175.309 23.8485C175.671 23.8919 176.038 23.8269 176.363 23.6616C176.689 23.4963 176.957 23.2384 177.135 22.9205L178.593 22.9206ZM173.264 20.4536H177.142C177.161 20.1966 177.126 19.9386 177.039 19.696C176.952 19.4533 176.815 19.2315 176.637 19.0448C176.459 18.858 176.244 18.7104 176.006 18.6115C175.767 18.5126 175.51 18.4646 175.252 18.4705C174.99 18.4672 174.73 18.5163 174.487 18.6148C174.245 18.7133 174.024 18.8592 173.839 19.0441C173.654 19.2289 173.507 19.4488 173.409 19.6909C173.31 19.9329 173.261 20.1923 173.264 20.4536H173.264Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_2528_1450">
            <rect
              width="204.001"
              height="68.0003"
              fill="white"
              transform="translate(-0.000488281)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default AppleDownloadIcon;
