import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authSlice } from "./auth/auth-slice.service";
import { photoSlice } from "./photo/photo-slice.service";
import { orderSlice } from "./order/order-slice.service";
import { creditSlice } from "./credit/credit-slice.service";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    photo: photoSlice.reducer,
    order: orderSlice.reducer,
    credit: creditSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
