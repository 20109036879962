import { Box, Grid, Typography, useTheme } from "@mui/material";

import CrossIcon from "../../../../fixtures/icons/cross.icon";

function PhotoUploadInstructions() {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        sx={{
          borderRadius: "50px 50px 0px 0px",
          borderTop: {
            md: "4px solid rgba(217, 217, 217, 0.05)",
          },
          borderRight: {
            md: "4px solid rgba(217, 217, 217, 0.05)",
          },
          borderLeft: { md: "4px solid rgba(217, 217, 217, 0.05)" },
          background: { md: "rgba(255, 255, 255, 0.05)" },
          height: "100%",
          padding: 4,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            alignSelf: { md: "center" },
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.text.secondary }}
              >
                Upload 7 photos with your face
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: { xl: 4 } }}>
              <Grid container>
                <Grid item>
                  <Typography variant="h5">
                    Include a variety of close-ups, facing front and different
                    angles.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: { xl: 4 } }}>
              <Grid container>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sx={{ zIndex: 4 }}>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: "20px",
                        boxShadow:
                          "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 9px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 36px 22px 0px rgba(0, 0, 0, 0.05), 0px 65px 26px 0px rgba(0, 0, 0, 0.01), 0px 101px 28px 0px rgba(0, 0, 0, 0.00), 0px 0px 70px 0px rgba(193, 81, 235, 0.30) inset",
                        backgroundImage: `${"url('/images/photo-upload-sample/photo-upload-sample-1.png')"}`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: {
                          xs: "136.166px",
                          sm: "136.166px",
                          md: "116.166px",
                          lg: "160px",
                          xl: "190px",
                        },
                        width: {
                          xs: "131.152px",
                          sm: "131.152px",
                          md: "101.152px",
                          lg: "135.442px",
                          xl: "165.442px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ zIndex: 3 }}>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: "20px",
                        marginLeft: { xs: -8, sm: -6, md: -4 },
                        boxShadow:
                          "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 9px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 36px 22px 0px rgba(0, 0, 0, 0.05), 0px 65px 26px 0px rgba(0, 0, 0, 0.01), 0px 101px 28px 0px rgba(0, 0, 0, 0.00), 0px 0px 70px 0px rgba(193, 81, 235, 0.30) inset",
                        backgroundImage: `${"url('/images/photo-upload-sample/photo-upload-sample-2.png')"}`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: {
                          xs: "123.938px",
                          sm: "123.938px",
                          md: "103.938px",
                          lg: "140px",
                          xl: "170px",
                        },
                        width: {
                          xs: "110.653px",
                          sm: "110.653px",
                          md: "90.653px",
                          lg: "118.271px",
                          xl: "148.271px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ zIndex: 2 }}>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: "20px",
                        marginLeft: { xs: -6, md: -4 },
                        boxShadow:
                          "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 9px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 36px 22px 0px rgba(0, 0, 0, 0.05), 0px 65px 26px 0px rgba(0, 0, 0, 0.01), 0px 101px 28px 0px rgba(0, 0, 0, 0.00), 0px 0px 70px 0px rgba(193, 81, 235, 0.30) inset",
                        backgroundImage: `${"url('/images/photo-upload-sample/photo-upload-sample-3.jpeg')"}`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: {
                          xs: "111.71px",
                          sm: "111.71px",
                          md: "91.71px",
                          lg: "120px",
                          xl: "150px",
                        },
                        width: {
                          xs: "99.536px",
                          sm: "99.536px",
                          md: "79.536px",
                          lg: "100.089px",
                          xl: "130.089px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ zIndex: 1 }}>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: "20px",
                        marginLeft: { xs: -6, md: -4 },
                        boxShadow:
                          "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 9px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 36px 22px 0px rgba(0, 0, 0, 0.05), 0px 65px 26px 0px rgba(0, 0, 0, 0.01), 0px 101px 28px 0px rgba(0, 0, 0, 0.00), 0px 0px 70px 0px rgba(193, 81, 235, 0.30) inset",
                        backgroundImage: `${"url('/images/photo-upload-sample/photo-upload-sample-4.png')"}`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: {
                          xs: "99.482px",
                          sm: "99.482px",
                          md: "79.482px",
                          lg: "100px",
                          xl: "130px",
                        },
                        width: {
                          xs: "89.323px",
                          sm: "89.323px",
                          md: "69.323px",
                          lg: "83.383px",
                          xl: "113.383px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: "20px",
                        marginLeft: { xs: -6, md: -4 },
                        boxShadow:
                          "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 9px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 36px 22px 0px rgba(0, 0, 0, 0.05), 0px 65px 26px 0px rgba(0, 0, 0, 0.01), 0px 101px 28px 0px rgba(0, 0, 0, 0.00), 0px 0px 70px 0px rgba(193, 81, 235, 0.30) inset",
                        backgroundImage: `${"url('/images/photo-upload-sample/photo-upload-sample-5.jpeg')"}`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: {
                          xs: "87.254px",
                          sm: "87.254px",
                          md: "67.254px",
                          lg: "80px",
                          xl: "110px",
                        },
                        width: {
                          xs: "78.922px",
                          sm: "78.922px",
                          md: "58.922px",
                          lg: "76.372px",
                          xl: "96.372px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginLeft: { xs: 0, lg: 2, xl: 4 },
                marginRight: { xs: 0, lg: 2, xl: 4 },
              }}
            >
              <Grid
                container
                sx={{
                  borderRadius: "15px",
                  border: "1px solid rgba(255, 255, 255, 0.04)",
                  background: "rgba(255, 255, 255, 0.08)",
                  backdropFilter: "blur(52px)",
                  padding: "10px 20px",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item justifyContent="center">
                      <CrossIcon color="#F92F6C" />
                    </Grid>
                    <Grid item>
                      <Typography variant="inverse">no sunglasses</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ marginRight: 2, marginLeft: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <CrossIcon color="#F92F6C" />
                    </Grid>
                    <Grid item>
                      <Typography variant="inverse">no nudity</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item justifyContent="center">
                      <CrossIcon color="#F92F6C" />
                    </Grid>
                    <Grid item>
                      <Typography variant="inverse">
                        only 1 person in each photo
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PhotoUploadInstructions;
