import {
  Button,
  Grid,
  Modal,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import CrossIcon from "../../../../fixtures/icons/cross.icon";

import Spacer from "../../../../components/utils/spacer.component";

function GeneratedPhotoModal({
  remote_url,
  openModal,
  setOpenModal,
}: {
  remote_url: string;
  openModal: boolean;
  setOpenModal: Function;
}) {
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(!openModal)}>
        <>
          <Grid container justifyContent="center" sx={{ height: "100vh" }}>
            <Grid item xs={12}>
              <Grid container sx={{ marginLeft: "24px", marginTop: "28px" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    {!belowBreakPoint ? (
                      <CrossIcon color="#FFF" />
                    ) : (
                      <Box>
                        <CrossIcon color="#FFF" />
                      </Box>
                    )}
                    {!belowBreakPoint && (
                      <>
                        <Spacer position="right" size="m" />
                        Back
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={6} lg={4} xl={3}>
              <Grid container>
                <Grid item>
                  <LazyLoadImage
                    effect="blur"
                    src={remote_url}
                    alt={remote_url}
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      </Modal>
    </>
  );
}

export default GeneratedPhotoModal;
