function CircularCheckPaidIcon() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <rect x="2.5" y="2.5" width="115" height="115" rx="57.5" fill="#C957F1" />
      <rect
        x="2.5"
        y="2.5"
        width="115"
        height="115"
        rx="57.5"
        fill="url(#paint0_radial_2361_1456)"
        fillOpacity="0.5"
      />
      <rect
        x="2.5"
        y="2.5"
        width="115"
        height="115"
        rx="57.5"
        stroke="url(#paint1_linear_2361_1456)"
        strokeWidth="5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.4678 37.6508C96.5798 39.7815 96.4983 43.1574 94.2857 45.1912L53.6703 82.5246C51.5301 84.4918 48.1622 84.4918 46.022 82.5246L25.7143 63.8579C23.5017 61.8241 23.4202 58.4481 25.5322 56.3175C27.6442 54.1868 31.15 54.1083 33.3626 56.1421L49.8462 71.2936L86.6374 37.4754C88.85 35.4416 92.3558 35.5201 94.4678 37.6508Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2361_1456"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(59.7235 120) rotate(90) scale(80.2941 60.547)"
        >
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_2361_1456"
          x1="24.3318"
          y1="-1.98729e-05"
          x2="113.709"
          y2="14.594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.36" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CircularCheckPaidIcon;
