import { useFormikContext } from "formik";
import { TextField, Box } from "@mui/material";

function FormField({ name }: { name: "email" }) {
  const { values, setFieldValue, handleSubmit } = useFormikContext<{
    email: string;
  }>();

  const pressEnter = ({ keyCode }: { keyCode: number }) => {
    if (keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": {
            backgroundColor: "rgba(209, 38, 212, 0.10)",
            borderRadius: "15px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
              borderColor: "rgba(209, 38, 212, 0.10)",
              " &.Mui-focused fieldset": {
                borderColor: "rgba(209, 38, 213, 0.45)",
              },
            },
          },
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          onKeyDown={pressEnter}
          name={name}
          placeholder="Enter your email"
          value={values[name]}
          onChange={(e) => setFieldValue(name, e.target.value)}
          sx={{
            input: { color: "rgba(0, 0, 0, 0.70)", fontFamily: "Switzer" },
          }}
        />
      </Box>
    </>
  );
}

export default FormField;
