import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getReq } from "../../services/api.services";
import Cookies from "js-cookie";

export const getCredit = createAsyncThunk("credit/index", async () => {
  const response = await getReq("/private/api/v1/credits");
  return response;
});

type SliceState = {
  getCreditObj: {
    status: "idle" | "pending" | "succeeded" | "failed";
    data: any;
    successMessage: string | null;
    errorMessage: string | null;
  };
};

const initialState: SliceState = {
  getCreditObj: {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  },
};

export const creditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCredit.pending, (state) => {
      state.getCreditObj.status = "pending";
    });
    builder.addCase(getCredit.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.getCreditObj.status = "succeeded";
      state.getCreditObj.data = data;
      Cookies.set("credit_balance", data.credit_balance, { expires: 30 });
    });
    builder.addCase(getCredit.rejected, (state, action) => {
      const { message } = action.error;
      state.getCreditObj.status = "failed";
      if (!message) return;
      state.getCreditObj.errorMessage = message;
    });
  },
});

export default creditSlice.reducer;

export const creditSelector = (state: RootState) => state.credit;

// export const {} = creditSlice.actions;
