import React from "react";
import { isLogin } from "../utils";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import routes from "./routes";

function PublicRoute() {
  const uuid: string = Cookies.get("remote_uuid") as string;
  if (uuid && isLogin()) {
    return <Navigate to={routes.PHOTO_POST_UPLOADED} />;
  } else if (isLogin()) {
    return <Navigate to={routes.PHOTO_UPLOAD} />;
  } else {
    return <Outlet />;
  }
}

export default PublicRoute;
