import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../store/store";
import {
  removePreviousValidations,
  uploadPhotos,
} from "../../../store/photo/photo-slice.service";
import { removeSession } from "../../../store/auth/auth-slice.service";
import { getCredit } from "../../../store/credit/credit-slice.service";

import routes from "../../../infrastructure/navigation/routes";

import HeaderContent from "../../../infrastructure/layout/components/header-content.component";

import PhotoUploadInstructions from "../components/photo-upload/photo-upload-instructions.component";
import PhotoUploadIllustrations from "../components/photo-upload/photo-upload-illustrations.component";

import ContentContainer from "../../../components/utils/content-container.component";

function PhotoUploadScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [photoValidator, setPhotoValidator] = useState<
    {
      index: number;
      loading: boolean;
      success: boolean | null;
      error: string | null;
      photo: string | null;
    }[]
  >([
    { index: 0, loading: false, success: null, error: null, photo: null },
    { index: 1, loading: false, success: null, error: null, photo: null },
    { index: 2, loading: false, success: null, error: null, photo: null },
    { index: 3, loading: false, success: null, error: null, photo: null },
    { index: 4, loading: false, success: null, error: null, photo: null },
    { index: 5, loading: false, success: null, error: null, photo: null },
    { index: 6, loading: false, success: null, error: null, photo: null },
  ]);

  const handleSubmit = async (values: { photos: string[] }): Promise<void> => {
    if (checkToRemoveLocalStorage()) {
      alert("Please reupload your photos");
      removeLocalStorage();
    }
    const photos: string[] = [];
    const recordedIndexes = localStorage.getItem("indexes") as string;
    if (recordedIndexes && recordedIndexes.length > 0) {
      const indexes: number[] = recordedIndexes
        .split(",")
        .map((index) => parseInt(index))
        .sort();
      indexes.forEach((index) => {
        const storage = localStorage.getItem(`photo-${index}`) as string;
        photos.push(JSON.parse(storage).remote_url);
      });
    }
    dispatch(uploadPhotos({ photos })).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(getCredit());
        Cookies.set("remote_uuid", payload.data.remote_uuid);
        navigate(routes.PHOTO_POST_UPLOADED);
      }
    });
  };

  const handleBack = (): void => {
    dispatch(removePreviousValidations()).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        localStorage.clear();
        dispatch(removeSession()).then(({ meta }) => {
          if (meta.requestStatus === "fulfilled") {
            navigate(routes.HOME);
          }
        });
      }
    });
  };

  const removeLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  const checkToRemoveLocalStorage = () => {
    const removeBy = localStorage.getItem("remove_by") as string;
    if (removeBy) {
      return new Date(removeBy) < new Date();
    } else {
      return false;
    }
  };

  const moveUserToPhotoPostUploadedScreen = () => {
    const uuid = Cookies.get("remote_uuid") as string;
    if (uuid) {
      navigate(routes.PHOTO_POST_UPLOADED);
    }
  };

  useEffect(() => {
    if (checkToRemoveLocalStorage()) {
      removeLocalStorage();
    }
    moveUserToPhotoPostUploadedScreen();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentContainer>
        <HeaderContent handleBack={handleBack} />
        <Formik
          initialValues={{
            photos: [],
          }}
          onSubmit={handleSubmit}
        >
          {() => (
            <>
              <Grid container spacing={2} sx={{ height: "100vh" }}>
                <Grid item xs={12} md={6}>
                  <PhotoUploadInstructions />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhotoUploadIllustrations
                    photoValidator={photoValidator}
                    setPhotoValidator={setPhotoValidator}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </ContentContainer>
    </>
  );
}

export default PhotoUploadScreen;
