function CircularCrossIcon() {
  return (
    <>
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
        <g filter="url(#filter0_b_1932_526)">
          <rect
            width="52"
            height="52"
            rx="26"
            fill="#D381F0"
            fillOpacity="0.15"
          />
          <path
            d="M27.5909 26L32.0441 21.5469C32.2554 21.3359 32.3743 21.0496 32.3745 20.751C32.3748 20.4524 32.2564 20.1659 32.0455 19.9545C31.8345 19.7432 31.5482 19.6243 31.2496 19.624C30.951 19.6238 30.6645 19.7422 30.4531 19.9531L26 24.4062L21.5469 19.9531C21.3355 19.7418 21.0489 19.623 20.75 19.623C20.4511 19.623 20.1645 19.7418 19.9531 19.9531C19.7418 20.1645 19.623 20.4511 19.623 20.75C19.623 21.0489 19.7418 21.3355 19.9531 21.5469L24.4062 26L19.9531 30.4531C19.7418 30.6645 19.623 30.9511 19.623 31.25C19.623 31.5489 19.7418 31.8355 19.9531 32.0469C20.1645 32.2582 20.4511 32.3769 20.75 32.3769C21.0489 32.3769 21.3355 32.2582 21.5469 32.0469L26 27.5937L30.4531 32.0469C30.6645 32.2582 30.9511 32.3769 31.25 32.3769C31.5489 32.3769 31.8355 32.2582 32.0469 32.0469C32.2582 31.8355 32.3769 31.5489 32.3769 31.25C32.3769 30.9511 32.2582 30.6645 32.0469 30.4531L27.5909 26Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_1932_526"
            x="-10.3"
            y="-10.3"
            width="72.6"
            height="72.6"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.15" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1932_526"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1932_526"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
}

export default CircularCrossIcon;
