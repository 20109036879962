import { ReactNode } from "react";
import { Box } from "@mui/material";

function ContentContainer({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        marginLeft: { sm: 0, md: 2, xl: 4 },
        marginRight: { sm: 0, md: 2, xl: 4 },
      }}
    >
      {children}
    </Box>
  );
}

export default ContentContainer;
